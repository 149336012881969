import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { AddToBasketTrackingFragmentFragmentDoc } from '@packages/gql/generated/shopping/AddToBasketTrackingFragmentFragmentDoc';
import { BreadcrumbBasketDataDocument } from '@packages/gql/generated/shopping/BreadcrumbBasketDataDocument';
import type { GTMEventGlycerinAddToCart } from '@packages/tracking';
import { type AddToBasketMutation } from '@packages/gql/generated/shopping/graphql';
import { useTracking } from '@packages/tracking';
import { useAtomValue } from 'jotai';
import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'urql';
import { selectedQuantity } from '../../../atoms';
import { getSkuPart } from '../../../helpers';
import { useHashParameters } from '../../../hooks';
import {
  getGlycerinArticleId,
  getGlycerinAvailability,
  getGlycerinOrigin,
  getGlycerinServices,
} from '../../helper';
import { getAddToBasketColorString } from './getAddToBasketColorString';
import { getAddToBasketVariantString } from './getAddToBasketVariantString';
import type { AddToBasketTrackingParameter } from './types';

/* GraphQL */ `
  fragment AddToBasketTrackingFragment on Product {
    akl
    # aplus -> itemOptionCommKey, hasServices and price with value has to be requested in product!
    itemOptionCommKey
    hasServices
    addToBasketTrackingAplus: aplus(language: $lang) @skip(if: $skipAplus) {
      ...GetGlycerinServicesFragment
    }
    availability {
      message
      ...GetGlycerinAvailabilityFragment
    }
    brand {
      name
    }
    defaultCategory
    dimensions {
      axisType
      type
      values {
        displayText
        isSelected
      }
    }
    longDescription
    marketcode
    media {
      url
    }
    name
    price {
      currency
      saving {
        value
      }
      value
      valueOld
      ...GetGlycerinServicesCurrencyFragment
    }
    orderNumber
    promotion
    sku
    ...GetGlycerinArticleIdFragment
  }
`;

/* GraphQL */ `
  query BreadcrumbBasketData($categoryId: ID!, $locale: String!) {
    breadcrumb(category: $categoryId, locale: $locale) {
      breadCrumb {
        name
      }
    }
  }
`;

export type UseAddToBasketTrackingProps = {
  data: FragmentType<typeof AddToBasketTrackingFragmentFragmentDoc>;
};

export const useAddToBasketTracking = ({ data }: UseAddToBasketTrackingProps) => {
  const unmaskedData = getFragmentData(AddToBasketTrackingFragmentFragmentDoc, data);
  const {
    akl,
    addToBasketTrackingAplus,
    availability,
    brand,
    defaultCategory,
    dimensions,
    longDescription,
    marketcode,
    media,
    name,
    orderNumber,
    price,
    promotion,
    sku,
  } = unmaskedData;
  const searchParams = useSearchParams();
  const ref = searchParams?.get('ref') || null;
  const navq = searchParams?.get('nav-q') || null;

  const { category, searchNo } = useHashParameters();
  const { locale } = useIntl();
  const dispatchGTMEvent = useTracking();
  const quantity = useAtomValue(selectedQuantity);

  const categoryId = category || defaultCategory || undefined;

  const [{ data: breadCrumbData }] = useQuery({
    query: BreadcrumbBasketDataDocument,
    variables: { categoryId: categoryId ?? '', locale: locale! },
    context: useMemo(() => ({ suspense: false }), []),
    pause: !categoryId,
  });

  const breadcrumbs: Array<string> = [];
  if (breadCrumbData?.breadcrumb?.breadCrumb) {
    breadCrumbData.breadcrumb.breadCrumb.forEach((item) => {
      breadcrumbs.push(item.name);
    });
  }
  const color = getAddToBasketColorString(dimensions);
  const variant = getAddToBasketVariantString(dimensions);

  // tracking parameters for atb request
  // implementation according to https://empiriecom.atlassian.net/browse/INFORM-2117
  const getTrackingParameter = (): AddToBasketTrackingParameter | undefined => {
    if (navq || searchNo) {
      return {
        source: 'Suche',
      };
    }
    if (categoryId && breadcrumbs) {
      return {
        source: categoryId,
        breadcrumbs,
      };
    }
    return undefined;
  };

  const trackLayerClosed = () => {
    dispatchGTMEvent({
      event: 'ga_event',
      eventAction: 'click',
      eventCategory: 'ATB_continueShopping',
      eventLabel: 'detailview_atb_modal',
    });
  };

  const trackToBasketClick = () => {
    dispatchGTMEvent({
      event: 'ga_event',
      eventAction: 'click',
      eventCategory: 'ATB_toBasket',
      eventLabel: 'detailview_atb_modal',
    });
  };

  const trackToCheckAndOrder = () => {
    dispatchGTMEvent({
      event: 'ga_event',
      eventAction: 'view',
      eventCategory: 'ATB_toCheckAndOrder',
      eventLabel: 'detailview_atb_modal',
    });
  };

  // TODO replace getSkuPart by size from data
  const trackAddToCart = ({
    lineItems,
  }: {
    lineItems: AddToBasketMutation['addToBasket']['lineItems'];
  }) => {
    dispatchGTMEvent({
      Merkzettel: 'nein',
      ecommerce: {
        add: {
          products: [
            {
              brand: brand?.name,
              category: navq ? `search:${navq}` : breadcrumbs?.join('/'),
              description: longDescription || undefined,
              dimension12: price.value > 0 ? 'nein' : 'ja',
              dimension15: getSkuPart(sku, 'Size'),
              dimension16: color,
              dimension18: price.saving?.value! > 0 ? 'ja' : 'nein',
              dimension19: promotion || undefined,
              dimension20: availability.message,
              dimension21: brand?.name,
              id: orderNumber,
              imageUrl: media?.[0].url || undefined,
              marketcode: marketcode || undefined,
              name,
              price: price.value / 100,
              quantity,
              sku,
              variant,
            },
          ],
        },
        cart: {
          products: lineItems,
        },
      },
      event: 'EECaddToCart',
    });
  };

  const trackCatalogGAddedProduct = () => {
    dispatchGTMEvent({
      event: 'ga_event',
      eventAction: 'viewLayer',
      eventCategory: 'catalog_addedproduct',
      eventValue: {
        orderNumber: sku.concat(promotion || ''),
        price: price.value,
        size: getSkuPart(sku, 'Size'),
      },
    });
  };

  const trackGlycerinAddToCart = () => {
    dispatchGTMEvent<GTMEventGlycerinAddToCart>({
      event: 'AddToCart',
      AddToCartData: {
        addedProducts: [
          {
            ...getGlycerinArticleId(unmaskedData),
            productId: akl,
            skuId: sku,
            quantity,
            currency: price.currency,
            displayedPrice: price.value,
            originalPrice: price.valueOld || undefined,
            ...getGlycerinAvailability(availability),
            ...getGlycerinServices({ aplus: addToBasketTrackingAplus, price }),
            ...getGlycerinOrigin(ref),
          },
        ],
        passive: false,
      },
    });
  };

  return {
    getTrackingParameter,
    trackLayerClosed,
    trackToBasketClick,
    trackToCheckAndOrder,
    trackAddToCart,
    trackCatalogGAddedProduct,
    trackGlycerinAddToCart,
  };
};
