import { atom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';

export type SelectedService = { sku: string; selected: true };

export const installmentCollapsed = atom(true);
export const selectedQuantity = atom(1);
export const selectedServices = atom<Array<{ sku: string; selected: true }>>([]);

export const HydrateAtoms = ({ children }: { children: JSX.Element }) => {
  useHydrateAtoms([
    [selectedQuantity, 1],
    [installmentCollapsed, true],
    [selectedServices, []],
  ]);

  return children;
};
