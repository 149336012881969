import type { FC } from 'react';
import React from 'react';
import { Button, Stack, Typography } from '@packages/shared';
import { ArrowMiniRight32 } from '@packages/themes/icons';
import { useIntl } from 'react-intl';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { SelectValuesFragmentFragmentDoc } from '@packages/gql/generated/shopping/SelectValuesFragmentFragmentDoc';
import { selectDimensionMessages } from './messages';
import { disabledStyle, defaultStyle } from '../styles';
import type { SetSelectedVariation } from '../../types/types';
import { VariationSelectionDialog } from '../VariationSelectionDialog';
import { VariationIcon } from '../VariationIcon';

/* GraphQL */ `
  fragment SelectValuesFragment on ProductDimension {
    displayName
    values {
      displayText
      image
      variationMainImage
      isSelected
      rgb
      sku
      availability {
        state
        message
      }
      ...VariationIconFragment
    }
  }
`;

export type SelectDimensionProps = {
  /**
   * The GraphQL dimension data for the given product
   */
  dimensionData: FragmentType<typeof SelectValuesFragmentFragmentDoc>;
  onChange: SetSelectedVariation;
};

/**
 * SelectDimension component
 *
 * used to display the selected dimension
 * opens dialog on click to select a different variation
 *
 * */
export const SelectDimension: FC<SelectDimensionProps> = ({ dimensionData, onChange }) => {
  const data = getFragmentData(SelectValuesFragmentFragmentDoc, dimensionData);
  const [open, setOpen] = React.useState(false);
  const { formatMessage } = useIntl();

  const selectedVariation = data.values.find((variation) => variation.isSelected === true);
  const isDisabled = data.values.length === 1;
  const style = isDisabled ? disabledStyle : defaultStyle;

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        sx={{ ...style, px: 1.5 }}
        fullWidth
        disabled={isDisabled}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
          width="100%"
        >
          {selectedVariation && (selectedVariation.rgb || selectedVariation.image) && (
            <VariationIcon imageSize="BUTTON" data={selectedVariation} />
          )}
          <Typography variant="body2" sx={{ flex: '1 1 100%' }} component="span" textAlign="left">
            {selectedVariation !== undefined
              ? selectedVariation.displayText
              : formatMessage(selectDimensionMessages.SelectVariation)}
          </Typography>
          {!isDisabled && <ArrowMiniRight32 sx={{ flex: '0 0 32px', height: '32px' }} />}
        </Stack>
      </Button>
      <VariationSelectionDialog
        dimensionData={data}
        open={open}
        handleClose={() => setOpen(false)}
        onChange={onChange}
      />
    </>
  );
};
