'use client';

import { Container } from '@packages/shared';
import { useLoginState } from '@packages/utilities';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useSetAtom } from 'jotai';
import config from '@packages/config';
import { remotePromotion } from '../../atoms';
import { decodeFromBase64 } from '../../utils/decodeFromBase64';
import { PromotionBannerContentWrapper } from './PromotionBannerContentWrapper';
import type { PromotionVariantsData, PromotionBannerProps } from './types';
import { fetchAndActivatePromotion } from './utils/fetch/fetchAndActivatePromotion';
import { ExpiredNotification } from './ExpiredNotification';
import { usePromotionTrigger } from './utils/promotionTriggerHook';
import { usePromotionBannerData } from './utils/usePromotionBannerData';
import { AbTestWrapper } from './ab-test/AbTestWrapper';
import { PromotionBannerEmpty } from './PromotionBannerEmpty';

const PromotionBannerContent = ({ promotionData }: { promotionData?: PromotionVariantsData }) => {
  // Tests and Storybook deliver the data by component-property - all other apps are feeding the swr-cache. So we try both sources here.
  const { data, hasExpiredData = false, isLoading } = usePromotionBannerData(promotionData);

  // for NJS-1196 allow to redeem voucher by internal SPA-link
  const query = Object.fromEntries(useSearchParams()?.entries() ?? []);
  const { loggedIn } = useLoginState();
  const setVoucher = useSetAtom(remotePromotion);
  const dataForRender = data?.selected || data?.active || data?.shop;
  const [showAsPaybackPromotion, setShowAsPaybackPromotion] = useState(
    !!dataForRender?.isPaybackPromotion,
  );
  usePromotionTrigger((customPromotionCode: string) => {
    fetchAndActivatePromotion(customPromotionCode, setVoucher);
  });
  useEffect(() => {
    const promotionCode =
      query.BonusNumber?.toString() || (query.P1 && decodeFromBase64(query.P1.toString()));
    if (promotionCode) {
      fetchAndActivatePromotion(promotionCode, setVoucher);
    }
    // NJS-1254: name loggedIn here to force rerender and rebuild usePromotionBannerData on login-status-change
  }, [query, setVoucher, loggedIn]);

  // only render anything if at least one data sample exists
  if (isLoading || !data || (!data.shop && !data.active && !data.selected)) {
    return <PromotionBannerEmpty />;
  }

  return (
    <>
      {/* INSPIRE-3345 use a separate defined component to prevent browser stuck */}
      <ExpiredNotification initialOpenNotification={hasExpiredData} />
      <Container
        sx={{
          bgcolor: showAsPaybackPromotion ? 'payback.main' : 'primary.main',
          color: showAsPaybackPromotion ? 'text.light' : 'primary.contrastText',
          minHeight: { xs: '4.5rem', lg: '3rem' },
          textAlign: 'center',
          paddingBlock: 1,
          lineHeight: 1.5,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          ...(config.styleVariant === 'dark' && {
            borderRadius: '1.875rem  0',
            textAlign: 'left',
          }),
        }}
      >
        <PromotionBannerContentWrapper
          promotionData={data}
          setShowAsPaybackPromotion={setShowAsPaybackPromotion}
        />
      </Container>
    </>
  );
};

/**
 * The Promotionbanner-Component displays a colored promotion box with interactive features to enable a served promotion
 *
 * @param promotionData Object of shop, active and/or selected promotion data
 * - shop: promotionData by shopId - the corresponding id for e.g. storefront, damenmode, ...
 * - active: promotionData by currently user-active bonuscode (read from session/cookie)
 * - selected: promotionData by user-selected bonuscode - this can be query-Params ?BonusCode or ?P1 (base64 encoded bonuscode)
 * @returns JSX.Element to be placed in PageLayout
 * @throws Error if fetching promotion data fails
 */
export const PromotionBanner = ({ promotionData }: PromotionBannerProps): JSX.Element => (
  <AbTestWrapper>
    <PromotionBannerContent promotionData={promotionData} />
  </AbTestWrapper>
);
