import { ClickAwayListener } from '@mui/material';
import type { SxProps, BadgeProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Box,
  type BoxProps,
  ConditionalWrapper,
  Badge as MuiBadge,
  Link,
  Typography,
  useDeviceType,
  localizeUrl,
} from '@packages/shared';
import type { MouseEvent, MouseEventHandler, ReactElement, ReactNode } from 'react';
import { useState } from 'react';
import type { Language } from '@packages/config';
import { useConfig } from '@packages/utilities';
import { SlotPopover } from './SlotPopover';

// Add support for the sx prop for consistency with the other branches.
const Anchor = styled('a')({
  color: 'inherit',
  textDecoration: 'none',
});

export type SlotProps = {
  /** Use router link or default <a> tags
   * @default true
   */
  useRouterLink?: boolean;
  /** add a language to the router link for route based i18n
   * @default undefined
   */
  language?: Language;
  /** Slot link */
  href?: string;
  /** Aria label for Slot link */
  linkAriaLabel?: string;
  /** Main icon component */
  icon: ReactElement;
  /** Slot title */
  title?: string;
  /** Set badgeCount to show primary badge in the top right corner on the icon */
  badgeCount?: number;
  /** Slot popover content */
  children?: ReactNode | ((leftPosition: number, closePopover: () => void) => ReactNode);
  /** OnClick handler */
  onClick?: MouseEventHandler<HTMLAnchorElement>;
} & Pick<BoxProps, 'sx'>;

const activeStyle: SxProps = {
  '&:after': {
    content: "''",
    position: 'absolute',
    inset: 'auto 0 0 0',
    height: 2,
    bgcolor: 'primary.main',
    pointerEvents: 'none',
  },
};

const Badge = styled(MuiBadge)<BadgeProps>({
  '& .MuiBadge-badge': {
    right: 2,
    top: 6,
  },
});

/** Base component for toolbar slots */
export const Slot = ({
  href,
  linkAriaLabel,
  icon,
  badgeCount,
  title,
  useRouterLink = true,
  language,
  children,
  onClick,
  sx,
}: SlotProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { isDesktop } = useDeviceType();
  const config = useConfig();

  const handleOpenPopover = (event: MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);

  const handleClosePopover = () => setAnchorEl(null);

  const handleTogglePopover = (event: MouseEvent<HTMLDivElement>) => {
    anchorEl === null ? handleOpenPopover(event) : handleClosePopover();
  };

  const localizedHref =
    href && language && !href.startsWith('http') ? localizeUrl(href, language, config) : href;
  const onSlotItemClick = (event: MouseEvent<HTMLDivElement>) => {
    if (!anchorEl && children) {
      event.preventDefault();
      event.stopPropagation();
      handleTogglePopover(event);
    } else {
      handleClosePopover();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClosePopover}>
      <Box
        onMouseEnter={isDesktop ? handleOpenPopover : undefined}
        onMouseLeave={isDesktop ? handleClosePopover : undefined}
        onClick={onSlotItemClick}
        data-testid="header-slot-wrapper"
        sx={sx}
      >
        {/* conditionaly wrap slot in a link */}
        <ConditionalWrapper
          condition={!!href}
          wrapper={(c) =>
            // eslint-disable-next-line no-nested-ternary
            !useRouterLink ? (
              // slot only exists for jelmolich and needs to be treated in a special way
              href === '/warehouse' ? (
                <Anchor
                  href={href!}
                  aria-label={linkAriaLabel ?? title}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    window.location.href = 'https://www.jelmoli.ch/';
                  }}
                >
                  {c}
                </Anchor>
              ) : (
                <Anchor href={localizedHref!} onClick={onClick} aria-label={linkAriaLabel ?? title}>
                  {c}
                </Anchor>
              )
            ) : (
              <Link
                noLinkStyle
                href={localizedHref!}
                locale={language}
                onClick={onClick}
                aria-label={linkAriaLabel ?? title}
              >
                {c}
              </Link>
            )
          }
        >
          {/* content wrapper (icon and title) */}
          <Box
            onClick={!isDesktop && !href ? handleTogglePopover : undefined}
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              py: 1,
              cursor: 'pointer',
              '> svg': {
                color: anchorEl ? 'primary.main' : 'auto',
                width: 32,
                height: 32,
              },
              ...(!!anchorEl && isDesktop && activeStyle),
            }}
            {...(isDesktop || !children
              ? {}
              : { title, 'aria-label': linkAriaLabel, role: 'button' })}
            data-testid="header-slot-content"
          >
            {/* conditionaly show primary badge at top right of the slot icon */}
            <ConditionalWrapper
              condition={!!badgeCount}
              wrapper={(ch) => (
                <Badge badgeContent={badgeCount} data-testid="header-slot-badge">
                  {ch}
                </Badge>
              )}
            >
              {icon}
            </ConditionalWrapper>
            {/* optionally show title under slot icon (mobile slots do not set title) */}
            {title && isDesktop && (
              <Typography variant="body3" textAlign="center" data-testid="header-slot-title">
                {title}
              </Typography>
            )}
          </Box>
        </ConditionalWrapper>
        {children && anchorEl && (
          <SlotPopover anchorEl={anchorEl} onClose={handleClosePopover}>
            {typeof children === 'function'
              ? children(anchorEl.getBoundingClientRect().left, handleClosePopover)
              : children}
          </SlotPopover>
        )}
      </Box>
    </ClickAwayListener>
  );
};
