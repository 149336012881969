import { useAbTesting, useMousemovement } from '@packages/abtesting';
import type { JSX } from 'react';
import { useEffect } from 'react';
import { useConfig } from '@packages/utilities';

export const AbTestWrapper = ({ children }: { children: any }): JSX.Element => {
  const { promotionBanner } = useConfig();
  const { setOutcome } = useAbTesting();
  const { mouseMoved } = useMousemovement();
  const testId = promotionBanner.abTest;
  useEffect(() => {
    if (testId && mouseMoved) {
      setOutcome(testId, { MOUSEMOVEMENT: 1 });
    }
  }, [setOutcome, mouseMoved, testId]);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
