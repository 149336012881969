import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { useIntl } from 'react-intl';
import { ProductPriceFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductPriceFragmentFragmentDoc';
import { useConfig } from '@packages/utilities';
import type { ProductPriceProps } from '@packages/shared/src/components/ProductPrice/ProductPrice';
import { ProductPrice } from '@packages/shared/src/components/ProductPrice/ProductPrice';
import { priceMessages } from './messages';

/* GraphQL */ `
  fragment ProductPriceFragment on ProductPrice {
    amount
    currency
    isStartPrice
    saving {
      type
      value
    }
    unit
    uvp
    value
    valueOld
    valueUnit
  }
`;

export type PriceProps = Omit<ProductPriceProps, 'price' | 'currency'> & {
  data: FragmentType<typeof ProductPriceFragmentFragmentDoc>;
};

// TODO(api-layer): this component will be unnecessary after implementation of the new reusable ProductPrice component

export const Price = ({ data, ...rest }: PriceProps) => {
  const { amount, currency, isStartPrice, saving, unit, uvp, value, valueOld, valueUnit } =
    getFragmentData(ProductPriceFragmentFragmentDoc, data);
  const { formatMessage } = useIntl();
  const {
    product: {
      price: { hasDiscountFlag },
    },
  } = useConfig();

  return (
    <ProductPrice
      {...rest}
      currency={currency}
      price={value}
      unit={unit || undefined}
      unitPrice={valueUnit || undefined}
      amount={amount || undefined}
      isStartPrice={!!isStartPrice}
      oldPrice={valueOld || undefined}
      discountType={
        // eslint-disable-next-line no-nested-ternary
        saving?.type ? (saving.type === 'CURRENCY' ? 'currency' : 'percent') : undefined
      }
      uvpLabel={
        uvp ? formatMessage(priceMessages.priceUVP) : formatMessage(priceMessages.priceDiscount)
      }
      discountInfoLabel={formatMessage(priceMessages.dicountLabel)}
      hasDiscountFlag={hasDiscountFlag && !!saving}
      saving={saving?.value}
    />
  );
};
