import { useState } from 'react';

import { Stack } from '@packages/shared';

import { AccountSlot } from '../../Mobile/Account';
import { LoginDialog } from '../../../../LoginDialog';

export const AccountTabContent = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);

  return (
    <Stack
      sx={{
        gridArea: 'content',
      }}
    >
      <AccountSlot onLogin={() => setShowLoginModal(true)} />
      <LoginDialog
        open={showLoginModal}
        handleClose={() => setShowLoginModal(false)}
        onClose={() => setShowLoginModal(false)}
      />
    </Stack>
  );
};
