import { type ParsedUrlQuery } from 'querystring';

import { type Config } from '@packages/config';
import { getAllTestParticipations } from '@packages/abtesting/src/useAbTesting/getAllTestParticipations';

export const ONBOARDING_QUERY_PARAM = 'lassDichInspirieren';
export const INSPIRING_SEARCH_TEST_ID = '2024_7_SB01';

export const INSPIRING_SEARCH_V2_PREVIEW_QUERY_PARAM = 'previewV2';
export const INSPIRING_SEARCH_V2_TEST_ID =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging' ? '2024_08_SB12_test' : '2024_08_SB12';

export const INSPIRING_SEARCH_V1 = 'v1' as const;
export const INSPIRING_SEARCH_V2 = 'v2' as const;

export const getTestParticipation = (cookies: Record<string, string>) => {
  const activeTests = getAllTestParticipations(cookies);

  const inspiringSearchV2Test = activeTests.find(
    ({ testId }) => testId === INSPIRING_SEARCH_V2_TEST_ID,
  );

  if (inspiringSearchV2Test && inspiringSearchV2Test.variant?.treatment) {
    return {
      testGroup: inspiringSearchV2Test.variant.treatment,
      testId: INSPIRING_SEARCH_V2_TEST_ID,
    };
  }

  const inspiringSearchTest = activeTests.find(({ testId }) => testId === INSPIRING_SEARCH_TEST_ID);
  if (inspiringSearchTest && inspiringSearchTest.variant?.treatment) {
    return {
      testGroup: inspiringSearchTest.variant.treatment,
      testId: INSPIRING_SEARCH_TEST_ID,
    };
  }

  return undefined;
};

/**
 * Get the activation status for Inspiring Search features (usable on server and client)
 */
export const getInspiringSearchSettingsInternal = (
  query: ParsedUrlQuery,
  config: Config,
  testParticipation?: {
    testGroup: string;
    testId: string;
  },
) => {
  const {
    search: { isInspiringSearchEnabled: isEnabledInConfig },
  } = config;

  const { testGroup, testId } = testParticipation || {};

  const forceAiEnhancement = !!query.alwaysAi || ONBOARDING_QUERY_PARAM in query;
  const forceV2Preview = INSPIRING_SEARCH_V2_PREVIEW_QUERY_PARAM in query;

  const inspiringSearchVersion =
    (testId === INSPIRING_SEARCH_V2_TEST_ID && testGroup === 'test') || forceV2Preview
      ? INSPIRING_SEARCH_V2
      : INSPIRING_SEARCH_V1;

  const isEnabledInAbTest =
    (testId === INSPIRING_SEARCH_V2_TEST_ID && testGroup !== 'off') ||
    (testId === INSPIRING_SEARCH_TEST_ID && testGroup === 'control') ||
    (!testId && !testGroup);

  const isInspiringSearchEnabled =
    (isEnabledInConfig && isEnabledInAbTest) || !!query.enableAi || forceAiEnhancement;

  return {
    forceAiEnhancement,
    isInspiringSearchEnabled,
    inspiringSearchVersion,
  };
};

/**
 * Get the activation status for Inspiring Search features (server-side)
 */
export const getInspiringSearchSettings = (
  query: ParsedUrlQuery,
  config: Config,
  cookies: Record<string, string>,
) => {
  const testParticipation = getTestParticipation(cookies);

  return getInspiringSearchSettingsInternal(query, config, testParticipation);
};
