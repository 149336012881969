import type { GridProps as MuiGridProps, GridTypeMap } from '@mui/material';
import { Grid as MuiGrid } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';

export type GridProps = MuiGridProps;

/** The Mui responsive layout grid adapts to screen size and orientation, ensuring consistency across layouts. https://mui.com/material-ui/react-grid/ */
export const Grid: OverridableComponent<GridTypeMap & MuiGridProps> = (props: GridProps) => (
  <MuiGrid {...props} />
);
