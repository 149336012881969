import { useDeviceType } from '@packages/shared';
import type { TemplateConfig, Template } from './types';
import { templates } from './config';

/**
 * Method to return the template name and image dimensions by determining a breakpoint depending on the device type.
 *
 * You can specify a "type", to choose where your image is placed (controls which template is choosen).
 *
 * @param type - constant `compact`, `gallery`, `thumbnail`, or `zoom`, depending on where the image is placed
 * @returns object containing the akamai-template suffix string to append to a img-src and the image dimensions.
 */
export function useResponsiveAkamaiTemplate(type: keyof TemplateConfig): Template {
  const { isDesktop, isTablet } = useDeviceType();

  const template = templates[type];

  if (isDesktop) {
    return template.desktop;
  }
  if (isTablet) {
    return template.tablet;
  }
  return template.mobile;
}
