import React from 'react';
import { Box, Image } from '@packages/shared';
import { getFragmentData, type FragmentType } from '@packages/gql/generated/shopping';
import { VariationIconFragmentFragmentDoc } from '@packages/gql/generated/shopping/VariationIconFragmentFragmentDoc';
import { useResponsiveAkamaiTemplate } from '../../hooks';

const IMAGESIZEBUTTON = 24;

/* GraphQL */ `
  fragment VariationIconFragment on ProductDimensionValue {
    rgb
    image
    displayText
  }
`;

export type VariationIconProps = {
  data: FragmentType<typeof VariationIconFragmentFragmentDoc>;
  /**
   * the size of the image Tile
   */
  imageSize: 'BUTTON' | 'DIALOG';
};

/**
 * Icon component
 *
 * used to display the selected dimension
 * opens dialog on click to select a different variation
 *
 * */
export const VariationIcon = ({ data, imageSize }: VariationIconProps) => {
  const { rgb, image, displayText } = getFragmentData(VariationIconFragmentFragmentDoc, data);

  const { templateName, imageDimensions } = useResponsiveAkamaiTemplate('variationSelect');
  const IMAGESIZE = imageSize === 'BUTTON' ? IMAGESIZEBUTTON : imageDimensions.height;

  return (
    <Box
      sx={{
        borderRadius: '50%',
        overflow: 'hidden',
        height: `${IMAGESIZE}px`,
        width: `${IMAGESIZE}px`,
        flex: `0 0 ${IMAGESIZE}px`,
        backgroundColor: rgb ? `#${rgb}` : undefined,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'grey.main',
      }}
    >
      {image && (
        <Image
          src={imageSize === 'DIALOG' ? `${image}?${templateName}` : image}
          alt={displayText || ''}
          width={IMAGESIZE}
          height={IMAGESIZE}
        />
      )}
    </Box>
  );
};
