import { useEffect } from 'react';

import { useCookies } from '@packages/utilities';

/**
 * Copy dynamic yield cookie cookie to a new cookie, according to INFORM-1113/CORE-614 and directly adapted from https://gitlab.com/empiriecom/search/fragment-header/-/merge_requests/1249
 *
 * NOTE according to the ticket comments in INFORM-1113 this should happen on the client, for unknown reasons, otherwise it would be better to handle this in the middleware
 */
export const useCopiedDynamicYieldCookie = () => {
  const { getCookies, setCookie } = useCookies();
  const cookies = getCookies();

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (cookies && cookies._dyid && !cookies._dyid_server) {
      // eslint-disable-next-line no-underscore-dangle
      setCookie(null, '_dyid_server', cookies._dyid, { maxAge: 31556951, path: '/', secure: true });
    }
  }, [cookies, setCookie]);
};
