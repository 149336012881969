import { useEffect } from 'react';
import { Box, Divider, IconButton, Typography } from '@packages/shared';
import { Minus32, Plus32 } from '@packages/themes/icons';
import { useAtom } from 'jotai';
import { useIntl } from 'react-intl';
import { selectedQuantity } from '../atoms';
import type { GetValidQuantityProps } from './getValidQuantity/getValidQuantity';
import { getValidQuantity } from './getValidQuantity';
import { quantitySelectionStyles } from './styles';
import { quantitySelectionMessages } from './messages';
import { getValidValues } from './getValidValues';

export type QuantitySelectionProps = {
  /**
   * Sets the maximum value the quantity can be.
   * @default 10
   */
  max?: number;
  /**
   * Sets the minimum value the quantity can be.
   * @default 1
   */
  min?: number;
  /**
   * Sets mow much the quantity of the step will be increased/decreased on button click.
   * @default 1
   */
  step?: number;
  /**
   * Get the quantity of the lineItem for miniDV
   * @default 1
   */
  lineItemQuantity?: number;
};

/**
 * <p>
 * This component is used to display some quantity. It comes with an increase and decrease button.
 * With the given props, you can adjust the <code>step</code> value with which the quantity will be decreased/increased.
 * You also can set a <code>min</code>/<code>max</code> value for the quantity and define the initial quantity with the <code>quantity</code> prop.
 * </p>
 * */
export const QuantitySelection = ({
  step = 1,
  min = 1,
  max = 10,
  lineItemQuantity,
}: QuantitySelectionProps) => {
  const values = getValidValues({
    step,
    min,
    max,
  });
  const [quantity, setQuantityAtom] = useAtom(selectedQuantity);
  const { formatMessage } = useIntl();

  // Quantity will be updated, if user uses miniDV
  useEffect(() => {
    if (lineItemQuantity) {
      setQuantityAtom(lineItemQuantity);
    }
  }, []);

  const setQuantity = (type: GetValidQuantityProps['type']) => {
    setQuantityAtom(
      getValidQuantity({
        currentQuantity: quantity,
        step: values.step,
        ...(type === 'increase'
          ? { type: 'increase', max: values.max }
          : { type: 'decrease', min: values.min }),
      }),
    );
  };

  return (
    <>
      <Divider />
      <Box sx={quantitySelectionStyles}>
        <Typography variant="body2" className="qsLabel">
          {formatMessage(quantitySelectionMessages.label)}
        </Typography>
        <IconButton
          onClick={() => {
            setQuantity('decrease');
          }}
          disabled={quantity <= values.min}
          icon={<Minus32 />}
          className="qsButton"
          aria-label={formatMessage(quantitySelectionMessages.decrease)}
        />
        <Typography variant="body2" className="qsValue">
          {quantity}
        </Typography>
        <IconButton
          onClick={() => {
            setQuantity('increase');
          }}
          disabled={quantity >= values.max}
          icon={<Plus32 />}
          className="qsButton"
          aria-label={formatMessage(quantitySelectionMessages.increase)}
        />
      </Box>
      <Divider />
    </>
  );
};
