/* @jsxImportSource react */

import config from '@packages/config';

import { SlotsContainer } from './SlotsContainer.tailwind';
import { SlotLoading } from './Slot.loading';

export const SlotsDefaultLoading = () => {
  const {
    headerSlots: { payback, localeSwitcher, warehouse, joe, serviceApi },
  } = config;

  return (
    <SlotsContainer>
      {localeSwitcher && <SlotLoading className="max-[419px]:hidden" />}
      {payback && <SlotLoading />}
      {joe && <SlotLoading />}
      {serviceApi && <SlotLoading className="max-md:hidden" />}
      {/* account */}
      <SlotLoading className="max-[374px]:hidden" />
      {warehouse && <SlotLoading className="max-md:hidden" />}
      {/* search slot is also hidden on checkout routes, but we'll just ignore that and hope it uses the minimal header anyways */}
      <SlotLoading className="lg:hidden" />
      {/* wishlist */}
      <SlotLoading />
      {/* basket */}
      <SlotLoading />
    </SlotsContainer>
  );
};
