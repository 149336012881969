import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgService32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M16 27c6.075 0 11-4.925 11-11S22.075 5 16 5 5 9.925 5 16s4.925 11 11 11m0 1C9.373 28 4 22.627 4 16S9.373 4 16 4s12 5.373 12 12-5.373 12-12 12" /><path d="M16 13.5a1 1 0 0 1 1 1V22a1 1 0 0 1-2 0v-7.5a1 1 0 0 1 1-1m0-1.5a1.5 1.5 0 1 0-.001-3.001A1.5 1.5 0 0 0 16 12" /></SvgIcon>;
};
const Memo = memo(SvgService32);
export default Memo;