import deepmerge from 'deepmerge';
import type { Config, DeepPartial } from '../default/types';
import defaultConfig, { getFullLocaleFromConfig, environment, customArrayMerge } from '../default';

const tenantId = 'universalat';

const config = deepmerge<Config, DeepPartial<Config>>(
  defaultConfig,
  {
    host: { name: tenantId, domain: 'universal.at' },
    clientId: 'UniversalAt',
    i18n: {
      defaultLocale: 'de-AT',
      locales: ['de-AT'],
      languages: ['de'],
    },
    tenantId,
    company: {
      name: 'Universal',
      seoTitleName: 'Universal.at',
    },
    headerSlots: {
      joe: true,
      serviceApi: `https://storage.googleapis.com/drupal-pages/${environment}/menus/${tenantId}/<locale>/bucket-service-flyout.json`,
    },
    assets: {
      cmsImage: {
        imageOptimizationEnabled: true,
        uriTransformEndpoint: `https://storage.googleapis.com/drupal-images-${tenantId}/$1/`,
      },
    },
    staticContent: {
      apiEndpoints: {
        webappJson: `https://cms.${tenantId}.empirie.<envTld>/<locale>/<path>?_format=webapp_json&flat`,
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          { params: { cmsPage: ['storefront'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe'] }, locale: 'de' },
        ],
      },
      cmsFontToTheme: {
        '.PoppinsLight, .font-normal-300': {
          fontWeight: 'fontWeightLight',
        },
        '.PoppinsMedium, .font-normal-500': {
          fontWeight: 'fontWeightMedium',
        },
        '.PoppinsSemibold, .font-normal-600': {
          fontWeight: 'fontWeightSemiBold',
        },
        '.PoppinsBold, .font-normal-700': {
          fontWeight: 'fontWeightBold',
        },
      },
    },
    search: {
      adServer: {
        mode: 'containerOnly',
      },
    },
    product: {
      cliplister: {
        customerId: '86453',
      },
      flixmedia: {
        distributorId: 18440,
        enabled: true,
      },
    },
    installmentCalculator: {
      hasLegalHints: false,
      InstallmentCalculatorCallToActionLayout: {
        layout: 'Layout3',
        openCalculatorIn: { type: 'Modal' },
      },
      hasSpecialCreditColors: true,
    },
    header: {
      desktopNavigationMode: 'hover',
    },
    order: {
      joe: {
        enabled: true,
      },
      premium: {
        enabled: false,
      },
      seals: {
        staatswappen: {
          enabled: true,
          url: 'http://www.staatswappen.at/',
        },
        handelsverband: {
          enabled: true,
          url: 'https://www.handelsverband.at/trustmark-austria/',
        },
        oeonline: {
          enabled: true,
          url: '/service-hilfe/ueber-uns/auszeichnungen/',
        },
      },
      assets: {
        premium: {
          getPremiumImage: ({ device }: { device: string }) =>
            `https://bilder.universal.at/empiriecom/io/universalat/de/BS3_CheckOut_${device}.jpg`,
          getPremiumIcon: () =>
            'https://bilder.universal.at/empiriecom/io/universalat/de/premiumicon_1.svg',
        },
        gifs: {
          success: {
            url: 'https://bilder.universal.at/i/empiriecom/io/de/thank_you_1_transparent.gif',
          },
        },
      },
      hasFlexikontoAdvantageClub: true,
    },
    footer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          { params: { type: ['full'] }, locale: 'de' },
          { params: { type: ['socket'] }, locale: 'de' },
        ],
      },
    },
    forms: {
      contact: {
        selectOptionsSubject: [
          { label: 'forms.type.subject.option.articleError', value: 'articleError' },
          { label: 'forms.type.subject.option.changed.email', value: 'emailChanged' },
          { label: 'forms.type.subject.option.changed.data', value: 'dataChanged' },
          { label: 'forms.type.subject.option.account', value: 'account' },
          { label: 'forms.type.subject.option.retoure', value: 'retoure' },
          { label: 'forms.type.subject.option.product', value: 'product' },
          { label: 'forms.type.subject.option.delivery', value: 'delivery' },
          { label: 'forms.type.subject.option.web', value: 'web' },
          { label: 'forms.type.subject.option.complaint.comment', value: 'complaint' },
          { label: 'forms.type.subject.option.return.data', value: 'return' },
          { label: 'forms.type.subject.option.coupons', value: 'coupons' },
          { label: 'forms.type.subject.option.newsletter', value: 'newsletter' },
          { label: 'forms.type.subject.option.other', value: 'other' },
        ],
      },
      newsletterUnsubscribe: {
        useDedicatedSubmitButtonTitleFor: ['unsubscribe|99', 'unsubscribeFlexikonto|98'],
        fields: [
          {
            id: 'newsletter',
            formType: 'radio',
            defaultValue: 'unsubscribe|99',
            options: [
              {
                id: 'frq-daily',
                value: 'frequency|0',
                messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.daily',
              },
              {
                id: 'frq-weekly',
                value: 'frequency|1',
                messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.weekly',
              },
              {
                id: 'unsubscribe',
                value: 'unsubscribe|99',
                messageKey: 'forms.newsletter.unsubscribe.type.unsubscribe',
              },
              {
                id: 'unsubscribe-flexikonto',
                value: 'unsubscribeFlexikonto|98',
                messageKey: 'forms.newsletter.unsubscribe.type.unsubscribeFlexikonto',
              },
              {
                id: 'pause',
                value: 'pause|-100',
                messageKey: 'forms.newsletter.unsubscribe.type.pause',
              },
            ],
            additionalForm: {
              id: 'pause',
              formType: 'select',
              type: 'pause',
              showOnlyWhenWhatSelected: 'pause',
              messageKeyPlaceholder: 'forms.newsletter.unsubscribe.type.pause.label',
              defaultValue: '7',
              options: [
                {
                  id: 'pause-7',
                  value: '7',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.7',
                },
                {
                  id: 'pause-14',
                  value: '14',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.14',
                },
                {
                  id: 'pause-21',
                  value: '21',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.21',
                },
                {
                  id: 'pause-30',
                  value: '30',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.30',
                },
              ],
            },
          },
        ],
      },
      newsletterSubscribe: {
        de: {
          unsubscribeLink: 'https://www.universal.at/service-hilfe/newsletter/abmelden/',
          voucherLink: 'https://www.universal.at/service-hilfe/gutscheine-rabatte/',
        },
      },
    },
    banner: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    nlSheetTexts: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/nl-sheet.json`,
      },
    },
    serviceLayer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
        bucketPages: `https://storage.googleapis.com/drupal-pages/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    seoContent: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/inspire-seofiles/<locale>_${tenantId}-<category>.json`,
      },
    },
    survey: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-surveys/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    promotionBanner: {
      apiEndpoints: {
        bucketEmptyPromotions: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/empty-promotion.json`,
        bucketPromotion: `https://storage.googleapis.com/promotion-data/${tenantId}/<language>/<code>.json`,
        bucketContentSnippet: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/<identifier>.json`,
      },
    },
    voucher: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/promotion-data/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    appBanner: { appUrl: 'https://app.adjust.com/7sq21cg_3c6m099' },
    tracking: {
      soastaId: 'VCRLH-5E9R4-TT9EZ-675H5-A9TNA',
      tagmanagerId: 'GTM-PZZ54R2',
      googleSiteVerificationID: ['s2QUnwdvTe-7ynyeA5_mieNSFv5fBht-tPHc8U2_BOs'],
      msvalidateID: '4067315BE5669CD6C74BDEC6F6AC5D47',
      pinterestRichPinVerificationID: 'aa7a73f8932f46f1c78af391ae7e3d8e',
      schemaOrgOrganisation: {
        'de-AT': {
          addressCountry: 'AT',
          addressRegion: 'SBG',
          addressLocality: 'Salzburg',
          alternateName: ['UNIVERSAL', 'Universal Versand', 'Universalversand'],
          areaServed: 'AT',
          contactEmail: 'service@universal.at',
          contactUrl: 'https://www.universal.at/service-hilfe/kontakt-beratung',
          description:
            'UNIVERSAL ist 1957 als Anbieter von Optik und Fotoartikeln gestartet, mittlerweile hat sich UNIVERAL zu einer traditionellen österreichischen Marke entwickelt. 2018 wurde der letzte Saisonkatalog versendet. Der größte Teil unserer Kundinnen und Kunden bestellt mittlerweile über unseren Onlineshop',
          email: 'service@universal.at',
          hoursAvailable: ['Mo-Su 7:00-22:00'],
          legalName: 'Universal Versand GmbH',
          logo: `/assets/logos/${tenantId}/logo.svg`,
          name: 'Universal',
          productSupported: '',
          sameAs: [
            'https://www.facebook.com/UniversalVersand',
            'https://www.instagram.com/universal.versand/',
            'https://www.pinterest.com/universal1/',
            'https://www.youtube.com/user/UniversalVersand1',
            'https://de.wikipedia.org/wiki/Universal_Versand',
          ],
          telephone: '+43-662-4485-8',
          url: 'https://www.universal.at',
          postalCode: '5020',
          privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz',
          potentialAction: {
            target: 'https://www.universal.at/s/',
          },
          streetAddress: 'Peilsteinerstraße 5-7',
        },
      },
    },
    externalScript: {
      kuecheCo: {
        key: 'pAYrueynYRcqna7lGLoVT39kF83Wy3PYENp81Yw0QJqeTyzAjXvc1FwGOzCm',
      },
    },
    headerInformation: {
      de: {
        description:
          'Universal.at - Online Shop für Mode, Möbel, Technik ✓ Gratis 3 Jahre XXL-Garantie ✓ Wunsch-Teilzahlung ✓ Gratis Versand ab EUR 75.- (ausgen. Spedition)',
        title: 'Universal.at | Mode - Möbel - Technik online kaufen',
        canonical: 'https://www.universal.at',
        robots: 'index,follow',
      },
    },
    // kim is renamed as "customerCentricFeedback"
    customerCentricFeedback: {
      tenantId: '1100',
    },
    dynamicYieldTracking: {
      key: 'bedfbdea4d3d014a8b838c361b7e67a5e683f21ac62f094f2dd353b478251146',
    },
    geoCountryLayer: {
      enabled: true,
      flag: 'at',
      alternatives: [
        {
          flag: 'de',
          shops: [
            { link: 'https://www.baur.de', title: 'baur.de' },
            { link: 'https://www.quelle.de', title: 'quelle.de' },
          ],
        },
        {
          flag: 'ch',
          shops: [{ link: 'https://www.ackermann.ch', title: 'ackermann.ch' }],
        },
      ],
    },
    redirectOverlay: {
      enabled: false,
      redirectLp: {
        de: '/service-hilfe/willkommen-quelle',
      },
      redirectParam: 'qatrdir',
    },
  },
  { arrayMerge: customArrayMerge },
);

export const getFullLocale = (localeOrLanguage: string | undefined) =>
  getFullLocaleFromConfig(localeOrLanguage, config);

// eslint-disable-next-line import/no-default-export
export default config;
