/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useSession, useConfig, logger } from '@packages/utilities';
import { useEffect } from 'react';
import { useSWRConfig } from 'swr';
import { createApiUrl } from './api';

/** fire and forget */
export const updater = (api: string, token: string, body?: string) =>
  fetch(api, {
    method: 'POST',
    headers: { 'x-ec-token': token, 'Content-Type': 'text/plain' },
    body,
  }).catch((e) => logger.error(e, 'Error on updating user search history'));

/* TODO: check if it is a requirement to update users search history on loading search-route and mounting, instead of adding query when search triggers. this behavior causes to missusage of useeffect, but it is the only possibility to add search strings from external search-urls to users search history. */

export const useSearchHistoryUpdate = (searchQuery: string | null) => {
  const { mutate } = useSWRConfig();
  const { jwtToken = '' } = useSession();

  const {
    apiEndpoints: { historyApiNew },
  } = useConfig();

  useEffect(() => {
    jwtToken !== '' &&
      searchQuery &&
      searchQuery.length > 2 &&
      mutate(
        createApiUrl(historyApiNew),
        updater(createApiUrl(historyApiNew), jwtToken, searchQuery),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, jwtToken]);
};
