/* @jsxImportSource react */

import type { FC, ReactNode } from 'react';

export type SlotsContainerProps = {
  children?: ReactNode;
};

export const SlotsContainer: FC<SlotsContainerProps> = ({ children }) => (
  <div className="flex gap-1.5 max-xl:mr-1 lg:gap-3">{children}</div>
);
