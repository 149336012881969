'use client';

import { useSearchParams } from 'next/navigation';
import { dateCompare } from './dateCompare';

export type TimeControl = {
  field_valid_from?: string | null;
  field_valid_to?: string | null;
  field_valid_until?: string | null;
  field_visible_from?: string | null;
  field_visible_until?: string | null;
  behavior_paragraph_time_control_valid_from?: string | null;
  behavior_paragraph_time_control_valid_until?: string | null;
};

export const filterTimeControl = (
  item: TimeControl,
  testDate: string | string[] | undefined | null = undefined,
): boolean => {
  // support valid from and valid until fields from paragraphs and content snippets INSPIRE-2298
  const {
    field_valid_from: validFrom,
    field_valid_to: validTo,
    field_valid_until: validUntil,
    field_visible_from: visibleFrom,
    field_visible_until: visibleUntil,
    behavior_paragraph_time_control_valid_from: behaviorValidFrom,
    behavior_paragraph_time_control_valid_until: behaviorValidUntil,
  } = item;

  return dateCompare({
    validFrom: behaviorValidFrom || validFrom || visibleFrom,
    validTo: behaviorValidUntil || validTo || visibleUntil || validUntil,
    testDate: Array.isArray(testDate) ? testDate[0] : testDate,
  });
};

export const useFilterTimeControl = (): ((item: TimeControl) => boolean) => {
  const query = Object.fromEntries(useSearchParams()?.entries() ?? []);
  const { TestDate } = query;
  return (item: TimeControl): boolean => filterTimeControl(item, TestDate);
};
