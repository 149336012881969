import { logger } from '@packages/utilities/src/logger/logger';
import { cached } from '@packages/shared/src/utils/cached';
import type { Promotion } from '../../types';
import { fetchShopPromotionData } from './fetchShopPromotionData';
import { fetchSelectedPromotionData } from './fetchSelectedPromotionData';
import { fetchActivePromotionData } from './fetchActivePromotionData';
import type { PromotionDataFetchInformation } from './fetch.types';

/**
 * fetch, filter and cleanup promotion data
 *
 * @param query holds different filter options by query params
 * @param cookies holds cookies to also filter information on
 * @param context
 * @param locale defines the language for the returned data
 * @param callOwnApi
 * @returns data for shop (by page context query param),
 * selected (by query param BonusNumber) and
 * active (by cookie value) promotions
 */
export const promotionBannerDataFetcher = async (
  query: PromotionDataFetchInformation['query'],
  cookies: PromotionDataFetchInformation['cookies'],
  context?: PromotionDataFetchInformation['context'],
  locale?: PromotionDataFetchInformation['locale'],
  callOwnApi?: PromotionDataFetchInformation['callOwnApi'],
): Promise<{
  active?: Promotion;
  selected?: Promotion;
  shop?: Promotion;
}> => {
  const [fetchedShopPromotionData, fetchedSelectedPromotionData, fetchedActivePromotionData] =
    await Promise.allSettled([
      fetchShopPromotionData({
        query,
        cookies,
        context,
        locale,
        callOwnApi,
      }),
      cached(
        `promotion-banner-selected-data-${locale}-${query.BonusNumber}-${query.P1}-${query.ParentCode}-${query.TestDate}`,
        async () =>
          fetchSelectedPromotionData({
            query,
            locale,
            callOwnApi,
          }),
        1000 * 60 * 60, // cache for 1 hour
      )(),
      fetchActivePromotionData({
        cookies,
        query,
        callOwnApi,
        locale,
      }),
    ]);
  const fetchedShopData =
    fetchedShopPromotionData.status === 'fulfilled' ? fetchedShopPromotionData.value : undefined;
  if (fetchedShopPromotionData.status === 'rejected') {
    logger.error(fetchedShopPromotionData.reason);
  }
  const fetchedSelectedData =
    fetchedSelectedPromotionData.status === 'fulfilled'
      ? fetchedSelectedPromotionData.value
      : undefined;
  if (fetchedSelectedPromotionData.status === 'rejected') {
    logger.error(fetchedSelectedPromotionData.reason);
  }
  const fetchedActiveData =
    fetchedActivePromotionData.status === 'fulfilled'
      ? fetchedActivePromotionData.value
      : undefined;
  if (fetchedActivePromotionData.status === 'rejected') {
    logger.error(fetchedActivePromotionData.reason);
  }

  // the promotion banner has an error state if all three fetches failed
  if (
    [fetchedShopPromotionData, fetchedSelectedPromotionData, fetchedActivePromotionData].every(
      (data) => data.status === 'rejected',
    )
  ) {
    throw Error('Failed to fetch any promotion data');
  }

  // I need to omit the complete prop if there is no value to prevent error: "'undefined' cannot be serialized as JSON"
  return {
    ...(fetchedShopData?.[0] && { shop: fetchedShopData?.[0] }),
    ...(fetchedSelectedData && { selected: fetchedSelectedData }),
    ...(fetchedActiveData && { active: fetchedActiveData }),
  };
};
