'use client';

import { useCallback, useEffect, useState } from 'react';
import { useConfig, useCookies } from '@packages/utilities';
import { useIsBot } from '@packages/tracking';

import { getTestCookieName, cookiePrefix, errorSuffix, participantSuffix } from './cookieNames';
import { getCachedTestRuleset } from './utilities.api';
import { apiUrl } from './apiUrl';

export type OutcomePayload = Record<string, string | number>;

export type Product = {
  /** This is the productId */
  sku: string;
  /** The masterId is optional and used for AKL */
  masterId?: string; // AKL
  /** Count of product e.g. 1 */
  count: number;
  /** Value in cent e.g. 99.99 = 9999 */
  value: number;
};

export type Payload = Record<string, string | number>;

export type Template = 'ATB' | 'RTH' | 'RPP';

export type APIPayload = {
  client: string;
  test: string;
  uuid: string;
  conversion: Array<string | number>;
  value: Array<string | number>;
  'User-Agent': string;
};

export const useAbTesting = () => {
  const { destroyCookie, getCookies, setCookie } = useCookies();
  const { tenantId: client } = useConfig();
  const isBot = useIsBot();
  // const isConsent = useCookieConsent("C0002");

  /**
   * If you call this function for the first time in the customer journey a participantID for the user is generated and the test for the user is started.
   * The internal handling generates an API call if there is no data from the 'test_<testid>' cookie. Otherwise, the data from the cookie is used to minimize API calls. The test-cookie expires after 24h.
   * This function is async. Please await the resultSet.
   * @param testId the testId as communicated in the test manifest
   * @returns the ruleset for the testId
   */
  const getTestRuleset = useCallback(
    async (testId: string) =>
      getCachedTestRuleset({
        testId,
        tenantId: client,
        cookies: getCookies(),
        setCookie,
        removeCookie: destroyCookie,
      }),
    [client, getCookies, destroyCookie, setCookie],
  );

  const getAllTestIds = useCallback(
    () =>
      Object.keys(getCookies())
        .filter(
          (key) =>
            key.startsWith(cookiePrefix) &&
            !key.endsWith(participantSuffix) &&
            !key.endsWith(errorSuffix),
        )
        .map((x) => x.replace(cookiePrefix, '')),
    [getCookies],
  );

  /**
   * Adds a `productId` to internal list of relevant products for the `testId`.
   * @param testId the testId as communicated in the test manifest
   * @param sku productId
   * @param group group relevant skus per test. This is used to segment the default outcomes per group (e.g. RPP_tr[reco1] )
   */
  const setRelevantProduct = (testId: string, sku: string, group?: string) => {
    const relevantPrdoucts = localStorage.getItem(testId);

    if (!relevantPrdoucts) {
      if (typeof group !== 'undefined') {
        localStorage.setItem(testId, JSON.stringify([`${sku}|${group}`]));
      } else localStorage.setItem(testId, JSON.stringify([sku]));
    } else {
      const products: string[] = JSON.parse(relevantPrdoucts);

      const filteredRelevantProducts = products
        // only return products, of which the sku is not already in the list
        .filter((product) => !product.includes(sku))
        // add new product to relevant products (if it already existed, it has been removed by the filter above)
        .concat(typeof group !== 'undefined' ? `${sku}|${group}` : sku);

      localStorage.setItem(testId, JSON.stringify(filteredRelevantProducts));
    }
  };

  /**
   * Returns a boolean for the `productId` if it was previously marked relevant for the `testId`.
   * @param testId the testId as communicated in the test manifest
   * @param sku productId
   * @returns an array of [sku, group] arrays that match the sku parameter
   */
  const getRelevantProduct = (
    testId: string,
    sku: string,
    masterId?: string,
  ): (string | undefined)[][] => {
    const skuList: Array<string> = JSON.parse(localStorage.getItem(testId) || '[]');
    const groupedProducts = skuList.map((entry) => entry.split('|'));
    return groupedProducts.filter((product) => product[0] === sku || product[0] === masterId) || [];
  };

  /**
   * Call the API and set an Outcome with the values from the payload. Internally adds a value for 'SESSIONID' from the value in the SESSIONID-Cookie.
   * @param {string} testId the testId as communicated in the test manifest
   * @param payload object of key/value pairs of type Payload
   * @returns void
   */
  const setOutcome = useCallback(
    (testId: string, payload: Payload) => {
      const currentCookies = getCookies();

      const conv = ['SESSIONID', 'isBot'];
      const val: Array<string | number> = [currentCookies.SESSIONID, isBot ? 1 : 0];

      const participantId = currentCookies[`${getTestCookieName(testId)}_participant`];

      if (!participantId) {
        return;
      }

      // generate payload for POST
      const apiPayload: APIPayload = {
        client,
        test: testId,
        uuid: participantId,
        conversion: conv.concat(Object.keys(payload)),
        value: val.concat(Object.values(payload)),
        'User-Agent': navigator.userAgent,
      };

      fetch(`${apiUrl}/servlet/Conversion`, {
        method: 'POST',
        body: JSON.stringify(apiPayload),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
    },
    [client, getCookies, isBot],
  );

  /**
   * Tracks a outcome for the `productId` for all active tests. Checks if the productId was marked as relevant per test and adds the '_tr' prefix plus the test `variant` to the `template` if true.
   * @param sku productId
   * @param masterId masterId (AKL)
   * @param template the template from which the function is called
   */
  const setDefaultViewOutcome = (sku: string, masterId: string, template: Template = 'RPP') => {
    const testIds = getAllTestIds();

    testIds.forEach((testId) => {
      // get relevant product status
      const relevantProduct = getRelevantProduct(testId, sku, masterId); // use the first match?
      let isProductRelevant = relevantProduct.length > 0;
      const [, group] = relevantProduct[relevantProduct.length - 1] || [];

      // if sku is relevant just add masterId to relevant products
      if (isProductRelevant) {
        setRelevantProduct(testId, masterId, group);
        // else check if AKL was previously marked as relevant and update isRelevantProduct
      } else {
        isProductRelevant = getRelevantProduct(testId, masterId).length > 0;
      }

      const outcomePayload: OutcomePayload = {};
      outcomePayload[
        isProductRelevant ? `${template}_tr${group === undefined ? '' : `[${group}]`}` : template
      ] = '1';

      // call setTestOutcome for the test
      setOutcome(testId, outcomePayload);
    });
  };

  /**
   *
   * @param products Array of all products for the tracking. Testrelevant products are calculated with the internal list of relevant products.
   * @param template the template from which the function is called.
   * @param orderId optional id of the current order.
   */
  const setDefaultProductOutcome = (
    products: Product[],
    template: Template,
    orderId: string | undefined = undefined,
  ) => {
    // find all tests in cookies
    const testIds = getAllTestIds();

    // generate RTH-Value and RTH-Count
    let rthValue = 0;
    let rthCount = 0;

    // add up total values for all products
    products.forEach((article) => {
      rthValue += article.value;
      rthCount += article.count;
    });

    testIds.forEach((testId) => {
      // get list off all products for the test
      const skuList: Array<string> = JSON.parse(localStorage.getItem(testId) || '[]');
      const groupedProducts = skuList.map((entry) => entry.split('|'));
      const groups = groupedProducts
        .map((p) => p[1])
        .filter((v: any, i: any, a: string | any[]) => a.indexOf(v) === i);

      // calculate RTH-Value_tr and RTH-Count_tr for the test
      const results: {
        group?: string;
        val: number;
        count: number;
      }[] = [];
      // for each group ..
      groups.forEach((group) => {
        const result = {
          group,
          val: 0,
          count: 0,
        };
        // .. iterate each product and add up values and counts for relevant product
        products.forEach((article) => {
          if (
            groupedProducts.filter((g) => g[0] === article.sku && g[1] === group).length > 0 ||
            (article.masterId !== undefined &&
              groupedProducts.filter((g) => g[0] === article.masterId && g[1] === group).length > 0)
          ) {
            // for AKL case when sku is not in relevant products. We just add it for all. List is unique.
            setRelevantProduct(testId, article.sku);
            result.val += article.value;
            result.count += article.count;
          }
        });

        results.push(result);
      });

      // generate payload for the test
      const outcomePayload: OutcomePayload = {};
      outcomePayload[`${template}_VALUE`] = rthValue;
      outcomePayload[`${template}_COUNT`] = rthCount;
      // iterate groups and generate unique entries per group or at least one unnamed entry if there is only an 'undefined' group
      results.forEach((result) => {
        outcomePayload[
          `${template}_VALUE_tr${result.group !== undefined ? `[${result.group}]` : ''}`
        ] = result.val;
        outcomePayload[
          `${template}_COUNT_tr${result.group !== undefined ? `[${result.group}]` : ''}`
        ] = result.count;
      });
      if (orderId && orderId !== '') {
        outcomePayload.ORDERID = orderId;
      }

      // call setTestOutcome for the test
      setOutcome(testId, outcomePayload);
    });
  };

  const setDefaultCustomerOutcome = (customertype: string) => {
    const testIds = getAllTestIds();

    testIds.forEach((testId) => {
      const outcomePayload = {
        CUSTOMERTYPE: customertype,
      };

      // call setTestOutcome for the test
      setOutcome(testId, outcomePayload);
    });
  };

  return {
    getTestRuleset,
    setRelevantProduct,
    getRelevantProduct,
    setOutcome,
    setDefaultProductOutcome,
    setDefaultViewOutcome,
    setDefaultCustomerOutcome,
  };
};

const useFirstMouseMovement = (onFirstMouseMovement: () => void) => {
  useEffect(() => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      const { userAgent } = navigator;
      if (!userAgent.toLowerCase().includes('www.empiriecom.com')) {
        document.addEventListener('mousemove', onFirstMouseMovement, { once: true });
        document.addEventListener('touchstart', onFirstMouseMovement, { once: true });
      }
    }

    return () => {
      document.removeEventListener('mousemove', onFirstMouseMovement);
      document.removeEventListener('touchstart', onFirstMouseMovement);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

/**
 * useMousemovement hook
 *
 * Use to find out, if the mouse has been moved at least once.
 * Excludes mouse movement, if the userAgent includes `www.empiriecom.com`.
 *
 * @returns `mouseMoved: true` if the mouse has been moved at least once on the page, `false` otherwise
 */
export const useMousemovement = () => {
  const [mouseMoved, setMouseMoved] = useState(false);

  const move = () => {
    if (!mouseMoved) {
      setMouseMoved(true);
    }
  };

  useFirstMouseMovement(move);

  return { mouseMoved };
};

/**
 * Sends an outcome of {MOUSEMOVEMENT: 1} for the given testId, if the mouse has been moved at least once. This is used to discard bots from the test results.
 *
 * @param testId the test id for which to send the outcome
 */
export const useMouseMovementOutcome = (testId: string) => {
  const { setOutcome } = useAbTesting();
  const { getCookies } = useCookies();

  // TODO this needs some adjustments if it should provide reliable "exactly-once" semantics
  // currently this only guarantees "exactly-once-per-parent-component-lifecycle", but will re-fire on re-mounts of the calling component
  useFirstMouseMovement(async () => {
    const cookieName = getTestCookieName(testId);

    // directly waiting for the ruleset using `getTestRuleset` would be better, but results in duplicate requests for the ruleset (with differing participantIds)
    // TODO The whole abtesting logic needs to be reworked to support request deduplication for the ruleset assignment/fetching, if that works this can be done in a better way
    const interval = setInterval(() => {
      if (cookieName in getCookies()) {
        clearInterval(interval);
        setOutcome(testId, { MOUSEMOVEMENT: 1 });
      }
    }, 500);
  });
};
