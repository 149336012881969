import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { Button, Link } from '@packages/shared';
import { Pdf32 } from '@packages/themes/icons';
import { useIntl } from 'react-intl';
import { DownloadItemFragmentFragmentDoc } from '@packages/gql/generated/shopping/DownloadItemFragmentFragmentDoc';
import { downloadItemMessages } from './messages';

/* GraphQL */ `
  fragment DownloadItemFragment on Download {
    link
    type
  }
`;

export type DownloadItemProps = {
  /**
   * Download data from current product.
   */
  data: FragmentType<typeof DownloadItemFragmentFragmentDoc>;
  /**
   * Decides wether or not the download icon should be rendered
   * @default true
   */
  renderIcon?: boolean;
  /**
   * Optional - displays the index as text next to the message.
   */
  idx?: number;
};

/**
 * The Download component is used to display a link with or without an icon.
 * The link is clickable and opens a downloadable file in a new tab.
 * */
export const DownloadItem = ({ data, renderIcon = true, idx }: DownloadItemProps) => {
  const { formatMessage } = useIntl();
  const { link, type } = getFragmentData(DownloadItemFragmentFragmentDoc, data);

  // if there is a new DownloadType, which is missing in messages, we return null
  if (!(type in downloadItemMessages)) {
    return null;
  }

  return (
    <Link href={link} noLinkStyle openInNewTab display="inline-flex">
      <Button
        variant="text"
        startIcon={renderIcon && <Pdf32 />}
        sx={[
          {
            p: 0,
            minHeight: 'inherit',
            justifyContent: 'start',
            ':hover': { background: 'none' },
          },
          !renderIcon && {
            typography: 'body3',
            textDecoration: 'underline',
            ':hover': { textDecoration: 'underline' },
          },
        ]}
      >
        {formatMessage(downloadItemMessages[type])} {idx}
      </Button>
    </Link>
  );
};
