import type { DownloadType } from '@packages/gql/generated/shopping/graphql';
import { defineMessages } from 'react-intl';

export const downloadItemMessages = defineMessages<DownloadType>({
  ASSEMBLY_INSTRUCTIONS: {
    defaultMessage: 'Aufbauanleitung',
    id: 'efficiencyFlagDownloads.type.assembly',
  },
  PRODUCT_DATASHEET: {
    defaultMessage: 'Produktdatenblatt',
    id: 'efficiencyFlagDownloads.type.productdatasheet',
  },
  SAFETY_DATASHEET: {
    defaultMessage: 'Sicherheitsdatenblatt',
    id: 'efficiencyFlagDownloads.type.safetydatasheet',
  },
  USER_MANUAL: {
    defaultMessage: 'Gebrauchsanleitung',
    id: 'efficiencyFlagDownloads.type.usermanual',
  },
  MANUFACTURER_WARRANTY: {
    defaultMessage: 'Hersteller Garantie',
    id: 'efficiencyFlagDownloads.type.manufacturerwarranty',
  },
  WARNING_LABEL: {
    defaultMessage: 'Warnhinweis',
    id: 'efficiencyFlagDownloads.type.warninglabel',
  },
});
