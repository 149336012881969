import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgTruck32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M20 5v4h3.971a2 2 0 0 1 1.627.837L30 16v5a2 2 0 0 1-2 2h-1.184c.112.314.184.647.184 1a3 3 0 1 1-6 0c0-.353.072-.686.184-1h-9.368c.112.314.184.647.184 1a3 3 0 1 1-6 0c0-.353.072-.686.184-1H4a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2zm4 17a2 2 0 1 0 .001 4.001A2 2 0 0 0 24 22M9 22a2 2 0 1 0 .001 4.001A2 2 0 0 0 9 22m14.971-12H20v12h1.778c.55-.609 1.337-1 2.222-1s1.672.391 2.222 1H28a1 1 0 0 0 1-1v-4.68l-4.215-5.901a1 1 0 0 0-.814-.419M19 6H4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h2.778c.55-.609 1.337-1 2.222-1s1.672.391 2.222 1H19zM9 19c1.12 0 2.173.403 2.987 1.126a.501.501 0 0 1-.664.748A3.474 3.474 0 0 0 9 20c-.876 0-1.694.312-2.325.874a.5.5 0 1 1-.664-.748A4.476 4.476 0 0 1 9 19m13.958-8c.653 0 1.265.318 1.639.854l1.801 2.573A1 1 0 0 1 25.579 16H21v-5Zm0 1H22v3h3.579l-1.801-2.573a.999.999 0 0 0-.82-.427" className="truck-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgTruck32);
export default Memo;