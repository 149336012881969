import type { AddToBasketTrackingFragmentFragment } from '@packages/gql/generated/shopping/graphql';

export function getAddToBasketColorString(
  dimensions: AddToBasketTrackingFragmentFragment['dimensions'],
) {
  return (
    dimensions
      ?.filter((dimension) => dimension?.axisType === 'COLOR')
      .pop()
      ?.values.filter((value) => value.isSelected === true)
      .map((selectedValue) => selectedValue.displayText)
      .join('') ?? ''
  );
}
