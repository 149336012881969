import type { ButtonProps } from '@packages/shared';
import { Box, Button, CircularProgress } from '@packages/shared';
import type { SetSelectedVariation } from '../../../types/types';

export type TileButtonProps = Omit<ButtonProps, 'onClick'> & {
  active: boolean;
  onClick: SetSelectedVariation;
  sku: string;
  selectedVariation: string | null;
};

export const TileButton = ({
  active,
  onClick,
  sku,
  children,
  selectedVariation,
  ...rest
}: TileButtonProps) => {
  const isLoading = selectedVariation === sku && !active;

  return (
    <Button
      {...rest}
      onClick={() => {
        if (!active) {
          onClick(sku);
        }
      }}
    >
      {isLoading && (
        <CircularProgress
          sx={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}
          size="s"
          color="secondary"
        />
      )}
      <Box
        sx={{
          p: 1,
          ...(isLoading && { visibility: 'hidden' }),
        }}
      >
        {children}
      </Box>
    </Button>
  );
};
