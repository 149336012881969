import type { GetServerSidePropsContext } from 'next';
import type { ParsedUrlQuery } from 'querystring';

import type {
  DestroyCookie,
  SetCookie,
} from '@packages/utilities/src/CookieProvider/CookieProvider';
import {
  nookieDestroyCookie,
  nookieParseCookies,
  nookieSetCookie,
} from '@packages/utilities/src/CookieProvider/CookieProvider';

import { setTestCookies, removeTestCookies } from './utilities.cookie';
import { getParticipantCookieName, getTestCookieName } from './cookieNames';
import type { TestRuleset } from './types';
import { apiUrl } from './apiUrl';

type TestRulesetFuncProps = {
  testId: string;
  tenantId: string;
  context?: GetServerSidePropsContext<ParsedUrlQuery>;
  cookies: {
    [x: string]: any;
  };
  setCookie?: SetCookie;
  removeCookie?: DestroyCookie;
};

/**
 * NOTE: this should probably be refactored or deprecated, since it mixes fetching data and cookie handling, and is overall a bit confusing
 */
export const getTestRulesetFromAPIAndWriteCookies = async ({
  testId,
  tenantId,
  cookies,
  setCookie,
  removeCookie,
  context,
}: TestRulesetFuncProps) => {
  // just a temporary solution to prevent next requests in development... the endpoint has to be updated to an api endpoint
  if (process.env.NEXT_PUBLIC_NODE_ENV === 'development') {
    return undefined;
  }
  const participantID = cookies[getParticipantCookieName(testId)] ?? '';
  let testRuleset: TestRuleset;

  // build get url with testId and client
  let getUrl = `${apiUrl.replace(
    '<env>',
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging' ? 'dev' : 'cloud',
  )}/servlet/Participant?client=${encodeURIComponent(tenantId)}&test=${testId}`;
  // if participant for the testId is in cookie, add it to the get url
  if (participantID) {
    getUrl = `${getUrl}&participant=${participantID}`;
  }
  let response: Response;
  try {
    response = await fetch(getUrl, {
      headers: { 'Content-Type': 'application/json' },
    });
    if (!response.ok) {
      throw new Error();
    }
  } catch (e) {
    return undefined;
  }
  const data = await response.json();

  if (typeof setCookie !== 'undefined') {
    if (data.participant) {
      // create test ruleset from data
      testRuleset = {
        participant: data.participant,
        variant: data.variant,
      };

      setTestCookies({ context, testId, setCookie, value: testRuleset });
    } else if (typeof removeCookie !== 'undefined') {
      removeTestCookies({ context, testId, removeCookie });
    }
  }

  return testRuleset;
};

export const getCachedTestRuleset = async ({
  testId,
  tenantId,
  context,
  cookies,
  setCookie,
  removeCookie,
}: TestRulesetFuncProps): Promise<TestRuleset> => {
  try {
    return JSON.parse(cookies[getTestCookieName(testId)]);
  } catch (e) {
    // ignore
  }

  return getTestRulesetFromAPIAndWriteCookies({
    testId,
    tenantId,
    cookies: cookies || nookieParseCookies(context),
    setCookie: setCookie || nookieSetCookie,
    removeCookie: removeCookie || nookieDestroyCookie,
    context,
  });
};
