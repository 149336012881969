import { useTracking } from '@packages/tracking';

export const useLayerTracking = () => {
  const dispatchTracking = useTracking();

  const trackViewLayer = (eventValue: string) => {
    dispatchTracking({
      event: 'ga_event',
      eventAction: 'viewLayer',
      eventCategory: 'noteLayer',
      eventLabel: 'detailview',
      eventValue,
    });
  };

  const trackViewServiceLayer = (eventValue?: string | null) => {
    dispatchTracking({
      event: 'ga_event',
      eventAction: 'viewLayer',
      eventCategory: 'serviceLayer',
      eventLabel: 'detailview',
      eventValue: eventValue || '',
    });
  };

  return {
    trackViewLayer,
    trackViewServiceLayer,
  };
};
