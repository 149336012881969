import { FlatIconButton, Skeleton, Box, useUrl } from '@packages/shared';
import type { FC } from 'react';
import { startTransition, useEffect, useState } from 'react';
import { BurgerText32 } from '@packages/themes/icons';
import { LoginDialog } from '../../../LoginDialog';
import type { NavItem } from '../types';
import { AccountSlot } from './Account';
import { MobileNavigationContent } from './Content';
import { NavigationDrawer } from './Drawer';
import { NavigationList } from './NavigationList';
import { useNavigationData } from '../useNavigationData';

export const MobileNavigation: FC<{
  rootCategoryId?: string;
  isBot?: boolean;
}> = ({ rootCategoryId, isBot }) => {
  const { data, loadFullData } = useNavigationData();

  const defaultActiveItem = isBot
    ? data.find((item: NavItem) => item.rootCategoryId === rootCategoryId)
    : undefined;

  const [isOpen, setIsOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

  const [activeItem, setActiveItem] = useState<NavItem | undefined>(defaultActiveItem);

  const url = useUrl();

  // reset subnavigation on open / close
  useEffect(() => setActiveItem(undefined), [isOpen]);

  // close navigation on location change
  useEffect(() => setIsOpen(false), [url]);

  // handle onDrawerButtonClick
  const onDrawerButtonClick = () => {
    startTransition(() => {
      setIsOpen(true);
      loadFullData?.();
    });
  };

  return (
    <>
      <FlatIconButton
        data-testid="navigation-button"
        aria-label="menu"
        onClick={onDrawerButtonClick}
        key={`menu-${isOpen}`}
      >
        <BurgerText32 />
      </FlatIconButton>
      {showLoginModal && (
        <LoginDialog
          open={showLoginModal}
          handleClose={() => startTransition(() => setShowLoginModal(false))}
          onClose={() => startTransition(() => setShowLoginModal(false))}
        />
      )}
      <NavigationDrawer open={isOpen} onUpdate={(open) => startTransition(() => setIsOpen(open))}>
        <MobileNavigationContent
          onClose={() => startTransition(() => setIsOpen(false))}
          resetNavigation={
            activeItem ? () => startTransition(() => setActiveItem(undefined)) : undefined
          }
          menuSlot={
            <NavigationList
              activeItem={activeItem}
              setActiveItem={(item) => startTransition(() => setActiveItem(item))}
              navigation={data}
            />
          }
          accountSlot={
            <AccountSlot
              onLogin={() => {
                startTransition(() => {
                  setIsOpen(false);
                  setShowLoginModal(true);
                });
              }}
            />
          }
        />
      </NavigationDrawer>
    </>
  );
};

/**
 * Loading fallback for mobile navigation button
 * Actually used only when component is whithout initial data and is purely CSR.
 * In future the fallback will be used when this component will become a server component
 */
export const MobileNavigationLoadingFallback = () => (
  <Box sx={{ p: 1 }}>
    <Skeleton variant="rectangular" width={32} height={32} />
  </Box>
);

// TODO: add mobile navigation error fallback component
