'use client';

import type { ReactNode } from 'react';
import { SWRConfig } from 'swr';

export const SwrConfigClientWrapper = ({
  children,
  fallback,
}: {
  children: ReactNode;
  fallback: Record<string, unknown>;
}) => <SWRConfig value={{ fallback, dedupingInterval: 3000 }}>{children}</SWRConfig>;
