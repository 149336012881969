import type { IconButtonProps as MuiIconButtonProps } from '@mui/material';
import { IconButton as MuiIconButton } from '@mui/material';
import {
  useTracking,
  type GlycerinButtonClickPayload,
  type GlycerinFormSubmitClickPayload,
  type GTMEventGlycerinFormSubmitClick,
  type GTMEventGlycerinButtonClick,
} from '@packages/tracking';

export type FlatIconButtonProps = MuiIconButtonProps & {
  clickTrackingProps?: GlycerinButtonClickPayload | GlycerinFormSubmitClickPayload;
};

/** Transparent icon button. Based on MUI IconButton https://mui.com/material-ui/api/icon-button/ */
export const FlatIconButton = ({
  children,
  onClick,
  clickTrackingProps,
  ...props
}: FlatIconButtonProps) => {
  const dispatchGTMEvent = useTracking();
  const { type } = props;
  const { category, target, detail, label, placement, variantIds, custom } =
    clickTrackingProps ?? {};
  return (
    <MuiIconButton
      onClick={(event) => {
        if (type === 'submit') {
          dispatchGTMEvent<GTMEventGlycerinFormSubmitClick>({
            event: 'FormSubmitClick',
            FormSubmitClickData: {
              category: category ?? 'form-submit-click',
              target,
              detail,
              label,
              placement,
              formId:
                clickTrackingProps && 'formId' in clickTrackingProps
                  ? clickTrackingProps.formId
                  : undefined,
              variantIds,
              custom,
            },
          });
        } else {
          dispatchGTMEvent<GTMEventGlycerinButtonClick>({
            event: 'ButtonClick',
            ButtonClickData: {
              category: category ?? 'flat-icon-button-click',
              target,
              detail,
              label,
              placement,
              variantIds,
              custom,
            },
          });
        }
        if (onClick) {
          onClick(event);
        }
      }}
      {...props}
    >
      {children}
    </MuiIconButton>
  );
};
