'use client';

import { useDeviceType } from '@packages/shared';
import { UspWrapper } from './UspWrapper';
import { useUspData } from '../../hooks/useUspData';

/**
 * Usp Component: Unique Selling Point
 *
 * @param data Object contains data
 * @returns {JSX.Element|null} to be placed in @apps/shopping pages or null if no data exist
 */
export const Usp = (): JSX.Element | null => {
  const data = useUspData();
  const { isMobile } = useDeviceType();

  if (data && !isMobile) {
    return <UspWrapper data={data} />;
  }

  return null;
};
