import { defineMessages } from 'react-intl';

export const ecoFriendlyDeliveryMessages = defineMessages({
  co2FriendlyFlag: {
    defaultMessage: 'CO₂-neutral',
    id: 'delivery.co2friendly.flag',
  },
  co2FriendlyText: {
    defaultMessage: '{flag} durch Kompensation',
    id: 'delivery.co2friendly.text',
  },
  dialogTitle: {
    defaultMessage: 'CO₂-neutral durch Kompensation',
    id: 'delivery.co2friendly.dialog.title',
  },
});
