/* @jsxImportSource react */

import { cn } from '@packages/shared/src/utils/cn';

export const ResponsiveFullBleedBackground = ({ className }: { className?: string }) => (
  <div
    className={cn(
      'absolute bottom-0 left-[var(--background-bleed)] right-[var(--background-bleed)] top-0 -z-10',
      className,
    )}
  />
);
