import type { EfficiencyFlagProps } from '@packages/shared';
import type { EfficiencyTypes } from '../types';

export const getEfficiencyFlagType = (
  props: EfficiencyTypes,
): EfficiencyFlagProps['type'] | null => {
  switch (props.type) {
    case 'POWEREFFICIENCY': {
      return props.hasOldLabel ? 'energyEfficiencyOld' : 'energyEfficiency';
    }
    case 'WETGRIP': {
      return 'wetGrip';
    }
    case 'FUELEFFICIENCY': {
      return 'fuelEfficiency';
    }
    case 'EXTERNALROLLINGNOISE': {
      return 'externalRollingNoise';
    }
    default: {
      return null;
    }
  }
};
