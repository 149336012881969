import type { ReactNode } from 'react';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { Box, Typography } from '@packages/shared';
import { Tracking32, Truck32 } from '@packages/themes/icons';
import { useIntl } from 'react-intl';
import { useConfig } from '@packages/utilities';
import { AvailabilityInfoFragmentFragmentDoc } from '@packages/gql/generated/shopping/AvailabilityInfoFragmentFragmentDoc';
import { EcoFriendlyDelivery } from '../EcoFriendlyDelivery';
import { availabilityInfoMessages } from './messages';
import { availabilityStateStyles } from './styles';

/* GraphQL */ `
  fragment AvailabilityInfoFragment on Product {
    availability {
      message
      state
    }
    delivery {
      deliverySize
    }
  }
`;

export type AvailabilityInfoProps = {
  /**
   * Data for availiability and delivery infos.
   *
   * `state`: Enum value of availabilities controlling color and display of message.
   * Can be either `AVAILABLE`, `NOT_YET_AVAILABLE`, `NOT_AVAILABLE` or `UNSELECTED`.
   *
   * `message`: Freetext availability message to be shown in corresponding color
   */
  data: FragmentType<typeof AvailabilityInfoFragmentFragmentDoc>;
  serviceLayer: ReactNode;
};

/**
 * AvailabilityInfo component displays product availability
 * */
export const AvailabilityInfo = ({ data, serviceLayer }: AvailabilityInfoProps) => {
  const {
    availability,
    delivery: { deliverySize },
  } = getFragmentData(AvailabilityInfoFragmentFragmentDoc, data);
  const { formatMessage } = useIntl();
  const {
    product: { hasCarbonHint },
  } = useConfig();

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        /* Define the first column to be automatically sized based on its content,
         * and the second column to take up the remaining available space */
        gridTemplateColumns: 'min-content auto',
        /* Define the two rows to be automatically sized based on content */
        gridTemplateRows: 'auto auto',
        /* Set the gap for rows and columns */
        gap: `${hasCarbonHint ? theme.spacing(0.5) : 0} ${theme.spacing(1)}`,
      })}
    >
      {/* First Row & First Column */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {deliverySize === 'DEFAULT' ? (
          <Tracking32 fontSize="medium" />
        ) : (
          <Truck32 fontSize="medium" />
        )}
      </Box>

      {/* First Row & Second Column */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={availabilityStateStyles.get(availability.state)}
          variant="body2"
          component="span"
        >
          {availability.state === 'UNSELECTED'
            ? formatMessage(availabilityInfoMessages.deliveryMessageUnselected)
            : availability.message ||
              formatMessage(availabilityInfoMessages.deliveryMessageFallback)}
        </Typography>
      </Box>

      {hasCarbonHint && (
        <>
          {/* Second Row & First Column */}
          <Box />

          {/* Second Row & Second Column */}
          <EcoFriendlyDelivery serviceLayer={serviceLayer} />
        </>
      )}
    </Box>
  );
};
