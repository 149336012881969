import { logger } from '@packages/utilities/src/logger/logger';

export const headerDefaultFetcher = <TResponse = unknown>(
  key: Array<string> | string,
): Promise<TResponse> =>
  fetch(
    typeof key === 'string' ? key : key[0],
    Array.isArray(key) && key[1] ? { headers: { 'x-ec-token': key[1] } } : {},
  ).then((res) => {
    if (!res.ok) {
      // throw error when response status not ok -> error boundary catches and logs the error
      throw new Error(`Endpoint: ${res.url} - Status ${res.status}: ${res.statusText}`);
    }
    return res.json();
  });

export const createApiUrl = (endpoint: string) =>
  `${typeof window !== 'undefined' ? '' : process.env.SERVER_HOST}${endpoint}`;

export type LoggingOptions = {
  componentName: string;
};

export const fetchRequiredStaticJson = async (url: string, { componentName }: LoggingOptions) => {
  try {
    const result = await fetch(url, { next: { revalidate: 60 } }); // TODO think about if 60 seconds is a good revalidation time for static JSON from bucket

    if (!result.ok) {
      throw new Error(`Status ${result.status}: ${result.statusText}`, {
        cause: {
          response: await result.text(),
        },
      });
    }

    return await result.json();
  } catch (error) {
    if (error instanceof Error) {
      logger.error({ component: componentName, url, error }, 'Error during data fetching');
    }

    // re-throw error to be caught by error boundary, as the data is required
    throw error;
  }
};
