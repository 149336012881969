import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { GetGlycerinArticleIdFragmentFragmentDoc } from '@packages/gql/generated/shopping/GetGlycerinArticleIdFragmentFragmentDoc';

/* GraphQL */ `
  fragment GetGlycerinArticleIdFragment on Product {
    orderNumber
    promotion
  }
`;

export type GetGlycerinArticleIdProps = FragmentType<
  typeof GetGlycerinArticleIdFragmentFragmentDoc
>;

export const getGlycerinArticleId = (data: GetGlycerinArticleIdProps) => {
  const { orderNumber, promotion } = getFragmentData(GetGlycerinArticleIdFragmentFragmentDoc, data);

  return { articleId: `${orderNumber}${promotion}` };
};
