import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWishlistCss32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M16 7c-8.1-9.4-24.7 4.9 0 22 24.7-17.1 8.1-31.4 0-22" className="wishlist-css-32_svg__fill" /><path d="M22 4c-2.1 0-4.3.9-6 3-1.8-2.1-4-3-6-3-7.4 0-13.3 11.7 6 25C35.3 15.7 29.4 4 22 4m0 1c2.6 0 4.8 1.7 5.6 4.2.6 2 1.6 9.1-11.7 18.5C2.7 18.4 3.7 11.2 4.3 9.2 5.1 6.7 7.3 5 10 5c1.9 0 3.8.9 5.3 2.6l.7.9.8-.9C18.2 6 20.1 5 22 5" className="wishlist-css-32_svg__border" /></SvgIcon>;
};
const Memo = memo(SvgWishlistCss32);
export default Memo;