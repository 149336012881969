import { useTheme } from '@mui/material';
import { useDeviceType } from '@packages/shared';
import { useConfig } from '@packages/utilities';

import { useIsCheckoutRoute } from './useIsCheckoutRoute';
import { AccountSlot } from './AccountSlot';
import { BasketSlot } from './BasketSlot';
import { SearchSlot } from './SearchSlot';
import { WishlistSlot } from './WishlistSlot';
import { WarehouseSlot } from './WarehouseSlot';
import { ServiceSlot } from './ServiceSlot';
import { LocaleSwitchSlot } from './LocaleSwitchSlot';
import { PaybackSlot } from './PaybackSlot';
import { JoeSlot } from './JoeSlot';
import { SlotsContainer } from './SlotsContainer';

export const SlotsDefault = () => {
  const {
    headerSlots: { payback, localeSwitcher, warehouse, joe, serviceApi },
  } = useConfig();
  const { isMobile, isDesktop } = useDeviceType();
  const isCheckoutRoute = useIsCheckoutRoute();
  const theme = useTheme();

  return (
    <SlotsContainer>
      {localeSwitcher && (
        // workaround: legacy fragment-header used a confusing mess of nth selectors and overrides
        // proper solution needs UI layout redesign
        <LocaleSwitchSlot sx={{ [theme.breakpoints.down(420)]: { display: 'none' } }} />
      )}
      {payback && <PaybackSlot />}
      {joe && <JoeSlot />}
      {!isMobile && serviceApi && <ServiceSlot />}
      {/* workaround: legacy fragment-header used (max-width: 374px) with inverted logic */}
      {/* proper solution needs UI layout redesign */}
      <AccountSlot sx={{ [theme.breakpoints.down(375)]: { display: 'none' } }} />
      {!isMobile && warehouse && <WarehouseSlot />}
      {!isDesktop && !isCheckoutRoute && <SearchSlot />}
      <WishlistSlot />
      <BasketSlot />
    </SlotsContainer>
  );
};
