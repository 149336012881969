import type { ReactNode } from 'react';
import { useState } from 'react';
import { useDeviceType } from '@packages/shared';
import { LoginDialog } from '../LoginDialog';
import type { LoginDialogProps } from '../LoginDialog';

export interface LoginDialogComposedProps
  extends Omit<LoginDialogProps, 'children' | 'open' | 'handleClose'> {
  /** Render prop function, so children can control LoginDialog */
  children: (showDialog: () => void, closeDialog: () => void) => ReactNode;
}

/** Composed component to include and control LoginDialog */
export const LoginDialogComposed = ({ children, ...dialogProps }: LoginDialogComposedProps) => {
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const isDesktop = useDeviceType();

  const openLoginDialog = () => setShowLoginDialog(true);
  const closeLoginDialog = () => {
    setShowLoginDialog(false);
  };

  return (
    <>
      <LoginDialog
        open={showLoginDialog}
        fullScreen={!isDesktop}
        {...dialogProps}
        handleClose={closeLoginDialog}
      />
      {children(openLoginDialog, closeLoginDialog)}
    </>
  );
};
