import { defineMessages } from 'react-intl';

export const addToBasketMessages = defineMessages({
  soldOut: { id: 'add-to-basket.sold-out', defaultMessage: 'Ausverkauft' },
  available: { id: 'add-to-basket.label', defaultMessage: 'In den Warenkorb' },
  headline: {
    defaultMessage: 'In den Warenkorb gelegt',
    id: 'addToBasket.layer.headline',
  },
  warningMessage: {
    defaultMessage: 'Bitte wähle zuerst eine Variante aus!',
    id: 'addToBasket.error.warning',
  },
  errorMessage: {
    defaultMessage:
      'Dein Artikel konnte leider nicht in den Warenkorb gelegt werden. Bitte versuche es später nochmal',
    id: 'addToBasket.error.message',
  },
  continueShopping: {
    id: 'add-to-basket.modal.continue-shopping',
    defaultMessage: 'Weiter shoppen',
  },
  navigateToCart: {
    id: 'add-to-basket.modal.navigate-to-basket',
    defaultMessage: 'Zum Warenkorb',
  },
  toCheckout: {
    id: 'add-to-basket.modal.navigate-to-checkout',
    defaultMessage: 'Zur Kasse',
  },
  miniDVSuccess: {
    id: 'add-to-basket-miniDV-success',
    defaultMessage: 'Dein Artikel wurde geändert',
  },
  miniDVButtonText: {
    id: 'add-to-basket-miniDV-button-text',
    defaultMessage: 'Änderungen übernehmen',
  },
});
