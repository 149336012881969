import type { SelectValuesFragmentFragment } from '@packages/gql/generated/shopping/graphql';
import React from 'react';
import { useIntl } from 'react-intl';
import type { DialogProps } from '@packages/shared';
import { Dialog, Stack } from '@packages/shared';
import type { SetSelectedVariation } from '../../types/types';
import { variationSelectionDialogMessages } from './messages';
import { VariationRow } from './VariationRow';

export type VariationSelectionDialogProps = {
  dimensionData: SelectValuesFragmentFragment;
  open: boolean;
  handleClose: NonNullable<DialogProps['handleClose']>;
  onChange: SetSelectedVariation;
};

/**
 * VariationSelectionDialog component
 *
 * dialog to select a different variation
 *
 * */
export const VariationSelectionDialog = ({
  dimensionData,
  open,
  handleClose,
  onChange,
}: VariationSelectionDialogProps) => {
  const { formatMessage } = useIntl();
  const hasImage = dimensionData.values.some(
    (value) => value.image !== null || value.rgb !== null || value.variationMainImage !== null,
  );

  const clickHandler = (sku: SelectValuesFragmentFragment['values'][number]['sku']) => {
    onChange(sku);
    handleClose();
  };

  return (
    <Dialog
      title={formatMessage(variationSelectionDialogMessages.headline, {
        dimension: dimensionData.displayName,
      })}
      dialogContent={
        <Stack>
          {dimensionData.values.map((value) => (
            <VariationRow
              value={value}
              hasImage={hasImage}
              clickHandler={clickHandler}
              key={value.sku}
            />
          ))}
        </Stack>
      }
      dialogContentSxProps={{ bgcolor: 'common.white' }}
      open={open}
      handleClose={handleClose}
    />
  );
};
