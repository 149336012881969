'use client';

import { ResponsiveNavigationLoading } from '../Navigation/ResponsiveNavigation/ResponsiveNavigation.loading';
import { ResponsiveHeaderLayout } from '../ResponsiveHeaderLayout';
import { LinkedLogo } from '../Toolbar/LinkedLogo';
import { SearchFieldLoading } from '../Toolbar/SearchField/loading';
import { SlotsDefaultLoading } from '../Toolbar/Slots/SlotsDefault.loading';

// TODO consider a skeleton for the AppBanner, although it would be kind of hard to determine when to show it, check cookies and user agent device maybe?

export const DefaultHeaderFallback = () => (
  <ResponsiveHeaderLayout
    logo={<LinkedLogo />}
    icons={() => <SlotsDefaultLoading />}
    searchField={() => <SearchFieldLoading />}
    navigation={<ResponsiveNavigationLoading />}
  />
);
