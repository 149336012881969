import type { SxProps } from '@mui/material';

export const defaultStyle: SxProps = {
  backgroundColor: 'grey.light',
  borderColor: 'grey.main',
  ':focus': {
    borderColor: 'text.dark',
  },
  ':hover': {
    borderColor: 'text.dark',
  },
};

export const selectedStyle: SxProps = {
  backgroundColor: 'grey.light',
  borderColor: 'text.dark',
  ':focus': {
    borderColor: 'text.dark',
  },
  ':hover': {
    borderColor: 'text.dark',
  },
};

export const disabledStyle: SxProps = {
  backgroundColor: 'common.white',
  borderColor: 'grey.main',
  color: 'text.darkTransparent',
  ':focus': {
    backgroundColor: 'common.white',
    borderColor: 'text.dark',
  },
  ':hover': {
    textDecoration: 'line-through',
    backgroundColor: 'common.white',
    borderColor: 'text.dark',
  },
};
