import { useCookies } from '@packages/utilities';
import type { Promotion } from '../types';

// determines if an active code should be removed
export const useShouldRemoveActiveExpiredCode = ({
  selectedData,
  promoDataForRender,
}: {
  selectedData?: Promotion;
  promoDataForRender?: Promotion;
}) => {
  const { getCookies } = useCookies();
  const { bonuscode: currentCodeByCookie, loyaltyCode: currentLoyaltyCodeByCookie } = getCookies();
  if (
    typeof window === 'undefined' ||
    selectedData ||
    !promoDataForRender ||
    !('cartCode' in promoDataForRender)
  ) {
    return false;
  }

  const activeBonusCodeMismatchRenderedCode =
    currentCodeByCookie && currentCodeByCookie !== promoDataForRender.cartCode;
  const activeLoyaltyCodeMismatchRenderedCode =
    currentLoyaltyCodeByCookie && currentLoyaltyCodeByCookie !== promoDataForRender.cartCode;

  return !!(activeBonusCodeMismatchRenderedCode || activeLoyaltyCodeMismatchRenderedCode);
};

export const useShouldAdHocActivateCode = ({
  activeData,
  selectedData,
  promoDataForRender,
}: {
  activeData?: Promotion;
  selectedData?: Promotion;
  promoDataForRender?: Promotion;
}) => {
  if (!selectedData || activeData || !promoDataForRender || !('cartCode' in promoDataForRender)) {
    return false;
  }
  return !!(selectedData.textsCode && selectedData.cartCode && promoDataForRender.cartCode);
};

// check isActive dependent on remote data - query-data is serverside-available while remote date is added clientside
export const shouldRenderAsChoiceLayer = ({
  selectedQueryData,
  selectedRemoteData,
  isActive,
  selectedData,
  activeData,
}: {
  selectedQueryData?: Promotion;
  selectedRemoteData?: Promotion | null;
  isActive: boolean;
  selectedData?: Promotion;
  activeData?: Promotion;
}) => {
  if (
    !selectedData?.textsCode ||
    !activeData?.textsCode ||
    selectedData.textsCode === activeData.textsCode
  ) {
    return false;
  }

  return !!(
    (selectedQueryData?.textsCode && !isActive) ||
    (!selectedRemoteData?.isExpired && selectedRemoteData?.textsCode && isActive)
  );
};
