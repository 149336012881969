import type { ThemeOptions } from '@mui/material/styles';
import mediaQuery from 'css-mediaquery';

export type Device = 'mobile' | 'tablet' | 'desktop';

export const createComponents = (device: Device): ThemeOptions['components'] => ({
  MuiUseMediaQuery: {
    defaultProps: {
      ssrMatchMedia: (query) => ({
        matches: mediaQuery.match(query, {
          // eslint-disable-next-line no-nested-ternary
          width: device === 'mobile' ? 360 : device === 'tablet' ? 768 : 1024,
        }),
      }),
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      boxSizing: 'border-box',
    },
  },
  MuiContainer: {
    defaultProps: {
      maxWidth: 'xl',
    },
    styleOverrides: {
      root: ({ theme: { unstable_sx: sx } }) =>
        sx({
          px: [0, 0, 1, 1, 0],
        }),
    },
  },
  MuiSvgIcon: {
    defaultProps: {
      fontSize: 'large',
    },
    styleOverrides: {
      fontSizeLarge: {
        fontSize: '32px',
      },
    },
  },
  MuiPopover: {
    defaultProps: {
      elevation: 1,
      marginThreshold: 0,
      transitionDuration: 0,
      BackdropProps: {
        invisible: true,
        transitionDuration: 0,
      },
    },
  },
  MuiListItemButton: {
    defaultProps: {
      disableRipple: process.env.NODE_ENV === 'test',
    },
  },
  MuiToggleButton: {
    defaultProps: {
      disableRipple: process.env.NODE_ENV === 'test',
    },
  },
  MuiStepButton: {
    defaultProps: {
      disableRipple: process.env.NODE_ENV === 'test',
    },
  },
  MuiIconButton: {
    defaultProps: {
      disableRipple: process.env.NODE_ENV === 'test',
    },
  },
  MuiButtonGroup: {
    defaultProps: {
      disableRipple: process.env.NODE_ENV === 'test',
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: process.env.NODE_ENV === 'test',
    },
  },
  MuiSwitch: {
    defaultProps: {
      disableRipple: process.env.NODE_ENV === 'test',
    },
  },
  // override button component appearance
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      variant: 'contained',
      color: 'inherit',
      disableRipple: process.env.NODE_ENV === 'test',
    },
    styleOverrides: {
      root: ({
        ownerState: { color, children },
        theme: {
          unstable_sx: sx,
          typography,
          palette: { text, grey },
        },
      }) =>
        sx({
          px: children ? 2 : 'unset',
          py: children ? '2px' : 'unset',
          color:
            color === 'warning' || color === 'inherit' || color === 'info' ? text.dark : text.light,
          typography: typography.body1,
          textTransform: 'none',
          svg: {
            width: '2rem',
            height: '2rem',
          },
          ':disabled': {
            color: grey.main,
            bgcolor: 'transparent',
            borderColor: grey.main,
          },
        }),
      // for only-icon buttons use startIcon prop
      startIcon: ({ ownerState: { size, children }, theme: { unstable_sx: sx } }) =>
        sx({
          // eslint-disable-next-line no-nested-ternary
          mr: children ? 1 : size === 'small' ? '-2px' : '-3.5px',
        }),
      endIcon: ({ ownerState: { size, children }, theme: { unstable_sx: sx } }) =>
        sx({
          // eslint-disable-next-line no-nested-ternary
          ml: children ? 1 : size === 'small' ? '-2px' : '-3.5px',
        }),
      containedInherit: ({
        theme: {
          unstable_sx: sx,
          palette: { grey, text },
        },
      }) =>
        sx({
          bgcolor: grey.light,
          border: '1px solid',
          borderColor: text.darkTransparent,
          ':hover': {
            bgcolor: grey.main,
          },
          ':active': {
            bgcolor: grey.main,
            borderColor: text.dark,
          },
          ':focus': {
            borderColor: grey.dark,
          },
        }),
      sizeSmall: ({ ownerState: { children }, theme: { unstable_sx: sx, typography } }) =>
        sx({
          minHeight: '1.75rem',
          minWidth: children ? 'auto' : 'unset',
          width: children ? undefined : '1.75rem',
          typography: typography.body3,
          svg: {
            width: '1.25rem',
            height: '1.25rem',
          },
        }),
      sizeMedium: ({ ownerState: { children }, theme: { unstable_sx: sx, typography } }) =>
        sx({
          minHeight: '2.25rem',
          minWidth: children ? 'auto' : 'unset',
          width: children ? undefined : '2.25rem',
          typography: typography.body2,
          svg: {
            width: '1.5rem',
            height: '1.5rem',
          },
        }),
      sizeLarge: ({ ownerState: { children }, theme: { unstable_sx: sx, typography } }) =>
        sx({
          minHeight: '2.75rem',
          minWidth: children ? 'auto' : 'unset',
          width: children ? undefined : '2.75rem',
          typography: typography.body1,
        }),
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        height: 4,
      },
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: process.env.NODE_ENV === 'test',
    },
    styleOverrides: {
      root: ({
        theme: {
          unstable_sx: sx,
          palette: { common },
          typography,
        },
      }) =>
        sx({
          typography: typography.h5,
          fontFamily: typography.fontFamily,
          bgcolor: common.white,
          textTransform: 'none',
          '&.Mui-selected': {
            color: 'unset',
          },
        }),
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({
        theme: {
          unstable_sx: sx,
          palette: { grey },
          typography: { h4, h5 },
        },
      }) =>
        sx({
          height: [48, 48, 64],
          px: 2,
          typography: { xs: h5, md: h4 },
          borderBottom: '1px solid',
          borderBottomColor: grey.main,
        }),
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: ({ theme: { unstable_sx: sx } }) =>
        sx({
          pt: 2.25,
        }),
    },
  },
  MuiSelect: {
    styleOverrides: {
      icon: ({
        theme: {
          unstable_sx: sx,
          palette: { text },
        },
      }) =>
        sx({
          color: text.dark,
        }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({
        theme: {
          unstable_sx: sx,
          palette: { grey, common },
        },
      }) =>
        sx({
          bgcolor: common.white,
          p: 0,
          '&.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
            },
          },
          '&.Mui-disabled': {
            bgcolor: grey.light,
          },
        }),
      disabled: ({
        theme: {
          unstable_sx: sx,
          palette: { grey },
        },
      }) =>
        sx({
          bgcolor: grey.light,
        }),
      input: ({
        theme: {
          unstable_sx: sx,
          palette: { text },
          typography,
        },
      }) =>
        sx({
          minHeight: '2.75rem',
          py: '0.813rem',
          px: '1.125rem',
          boxSizing: 'border-box',
          typography: typography.body2,
          fontFamily: typography.fontFamily,
          color: text.dark,
        }),
      notchedOutline: ({
        theme: {
          unstable_sx: sx,
          palette: { grey },
        },
      }) =>
        sx({
          top: 0,
          minHeight: '2.75rem',
          borderColor: grey.dark,
          legend: {
            display: 'none',
          },
        }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: ({
        theme: {
          unstable_sx: sx,
          palette: { text, error },
          typography,
        },
      }) =>
        sx({
          m: 0,
          typography: typography.body2,
          fontFamily: typography.fontFamily,
          color: text.dark,
          '&.Mui-error': {
            color: error.dark,
          },
        }),
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({
        theme: {
          unstable_sx: sx,
          typography,
          palette: { text },
        },
      }) =>
        sx({
          typography: typography.body2,
          fontFamily: typography.fontFamily,
          color: text.dark,
          transform: 'none',
          '&.Mui-focused, &.Mui-error': {
            color: text.dark,
          },
        }),
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: ({ theme: { unstable_sx: sx, typography } }) =>
        sx({
          typography: typography.body2,
        }),
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      // TODO: improve checkbox theming
      root: ({
        theme: {
          unstable_sx: sx,
          palette: { text },
        },
      }) =>
        sx({
          paddingTop: 0,
          paddingBottom: 0,
          color: text.dark,
          // NOTE: styleOverrides.checked and styleOverrides.indeterminate exist, but do not work for `color` due to specificity issues with another color rule from MUI
          '&:not(.Mui-disabled).Mui-checked, &:not(.Mui-disabled).Mui-indeterminate': {
            color: text.dark,
          },
          '&.Mui-focusVisible svg, &.Mui-focused svg': {
            strokeWidth: 2,
          },
        }),
    },
  },
  MuiRadio: {
    styleOverrides: {
      // TODO: improve checkbox theming
      root: ({
        theme: {
          unstable_sx: sx,
          palette: { text },
        },
      }) =>
        sx({
          paddingTop: 0,
          paddingBottom: 0,
          color: text.dark,
          // NOTE: styleOverrides.checked and styleOverrides.indeterminate exist, but do not work for `color` due to specificity issues with another color rule from MUI
          '&:not(.Mui-disabled).Mui-checked, &:not(.Mui-disabled).Mui-indeterminate': {
            color: text.dark,
          },
          '&.Mui-focusVisible svg, &.Mui-focused svg': {
            strokeWidth: 2,
          },
        }),
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: ({ theme: { unstable_sx: sx, typography } }) =>
        sx({
          fontFamily: typography.fontFamily,
          whiteSpace: 'normal',
        }),
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: ({ ownerState: { severity }, theme: { unstable_sx: sx, palette, spacing } }) =>
        sx({
          bgcolor: severity ? palette[severity].light : 'auto',
          '&.MuiPaper-root': {
            maxWidth: '100%',
            borderColor: severity ? palette[severity].main : 'auto',
            color: palette.text.dark,
            padding: spacing(0.5, 2, 0.5, 0.5),
            '> .MuiAlert-message': {
              alignSelf: 'center',
              fontSize: '0.75rem',
              lineHeight: '1.25rem',
              paddingTop: 0.3,
              paddingBottom: 0.2,
            },
          },
          '.MuiAlert-icon': {
            p: 0,
          },

          '.MuiAlert-action': {
            p: 0,
          },
        }),
      outlined: ({ theme: { unstable_sx: sx, typography } }) =>
        sx({
          p: 0.5,
          border: '1px solid',
          '.MuiAlert-icon': {
            mr: 0.5,
            svg: {
              width: 24,
              height: 24,
            },
          },
          '.MuiAlert-message': {
            typography: typography.body2,
            p: 0,
          },
          '.MuiAlert-action': {
            button: {
              p: 0,
              mr: 1,
            },
            svg: {
              width: 24,
              height: 24,
            },
          },
        }),
      standard: ({ theme: { unstable_sx: sx, typography } }) =>
        sx({
          borderTop: '3px solid',
          p: 1,
          '.MuiAlert-icon': {
            mr: 1,
            svg: {
              width: 32,
              height: 32,
            },
          },
          '.MuiAlert-message': {
            p: 0,
            typography: typography.body1,
          },
          '.MuiAlert-action': {
            button: {
              p: 0,
              mx: 1,
            },
            svg: {
              width: 32,
              height: 32,
            },
          },
        }),
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      root: ({ theme: { unstable_sx: sx } }) =>
        sx({
          width: '90%',
          maxWidth: 600,
        }),
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({
        theme: {
          unstable_sx: sx,
          palette: { text, grey },
        },
      }) =>
        sx({
          px: 2,
          py: 0.5,
          color: text.dark,
          ':hover': {
            bgcolor: grey.light,
          },
        }),
    },
  },
  MuiBadge: {
    defaultProps: {
      color: 'primary',
    },
  },
  MuiRating: {
    styleOverrides: {
      root: ({
        theme: {
          unstable_sx: sx,
          palette: { text },
        },
      }) =>
        sx({
          color: text.dark,
          stroke: text.dark,
          strokeWidth: '2px',
        }),
      label: { paddingLeft: 4, paddingRight: 4 },
      sizeSmall: {
        fontSize: '16px',
      },
      sizeMedium: {
        fontSize: '18px',
      },
      iconEmpty: { color: 'transparent' },
      sizeLarge: ({ theme: { unstable_sx: sx } }) =>
        sx({
          label: {
            // do not set gap, because of hover shift
            px: 0.5,
          },
          fontSize: ['32px', '32px', '32px'],
        }),
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      li: ({
        theme: {
          unstable_sx: sx,
          typography,
          palette: { text },
        },
      }) =>
        sx({
          typography: typography.body2,
          a: {
            color: text.darkTransparent,
          },
          ':last-child': {
            fontWeight: typography.fontWeightBold,
            'a, span': {
              color: text.dark,
            },
          },
        }),
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      action: {
        marginRight: '-12px',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: ({
        ownerState: { color },
        theme: {
          unstable_sx: sx,
          palette: { text, grey },
        },
      }) =>
        sx({
          ...(color === 'default' && {
            backgroundColor: grey.light,
          }),
          ':focus': {
            border: `solid 2px ${text.dark}`,
          },
        }),
      icon: ({
        theme: {
          unstable_sx: sx,
          palette: { text },
        },
      }) =>
        sx({
          color: text.dark,
          ':hover': {
            color: text.darkTransparent,
          },
        }),
      deleteIcon: ({
        theme: {
          unstable_sx: sx,
          palette: { text },
        },
      }) =>
        sx({
          color: text.dark,
          ':hover': {
            color: text.darkTransparent,
          },
        }),
    },
  },
  MuiTable: {
    styleOverrides: {
      root: ({ theme: { unstable_sx: sx } }) =>
        sx({
          tableLayout: 'fixed',
        }),
    },
  },
  MuiTableCell: {
    styleOverrides: {
      body: ({ theme: { unstable_sx: sx, typography } }) =>
        sx({
          typography: typography.body2,
          ':first-of-type': {
            fontWeight: typography.fontWeightBold,
            verticalAlign: 'top',
          },
        }),
      head: ({
        theme: {
          unstable_sx: sx,
          palette: { grey },
          typography,
        },
      }) =>
        sx({
          typography: typography.h5,
          bgcolor: grey.light,
        }),
      root: ({
        theme: {
          unstable_sx: sx,
          palette: { grey },
        },
      }) =>
        sx({
          border: '2px solid',
          borderColor: grey.light,
          px: 1.5,
          py: 1,
          wordBreak: 'break-word',
        }),
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      label: ({ theme: { unstable_sx: sx, typography } }) =>
        sx({
          fontSize: typography.body3.fontSize,
          lineHeight: typography.body3.lineHeight,
          '&.MuiStepLabel-alternativeLabel': {
            marginTop: 0,
          },
        }),
    },
  },
});
