import { useTracking } from '@packages/tracking';

export const useServiceLayerTracking = () => {
  const dispatchTracking = useTracking();

  // SERVICE_LAYER
  const trackServiceLayerClick = (serviceLayerID: string) => {
    dispatchTracking({
      event: 'ga_event',
      eventAction: 'click',
      eventCategory: 'service_layer',
      eventValue: serviceLayerID,
    });
  };

  // EcoFriendlyDelivery
  const trackEcoFriendlyDeliverySeen = (serviceLayerID: string) => {
    dispatchTracking({
      event: 'ga_event',
      eventAction: 'seen',
      eventLabel: 'detailview',
      eventValue: serviceLayerID,
    });
  };
  const trackEcoFriendlyDeliveryClick = (serviceLayerID: string) => {
    dispatchTracking({
      event: 'ga_event',
      eventAction: 'clicked',
      eventLabel: 'detailview',
      eventValue: serviceLayerID,
    });
  };

  return {
    trackServiceLayerClick,
    trackEcoFriendlyDeliverySeen,
    trackEcoFriendlyDeliveryClick,
  };
};
