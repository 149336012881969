import { Dialog, Flag, Typography } from '@packages/shared';
import { type ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ecoFriendlyDeliveryMessages } from './messages';
import { useServiceLayerTracking } from '../Tracking/hooks';

const serviceLayerId = 'eco_delivery_co2neutral';

export type EcoFriendlyDeliveryProps = {
  serviceLayer: ReactNode;
};

/**
 * EcoFriendlyDelivery component
 * */
export const EcoFriendlyDelivery = ({ serviceLayer }: EcoFriendlyDeliveryProps) => {
  const { formatMessage } = useIntl();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { trackEcoFriendlyDeliveryClick, trackEcoFriendlyDeliverySeen } = useServiceLayerTracking();

  useEffect(() => {
    trackEcoFriendlyDeliverySeen(serviceLayerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickHandler = () => {
    trackEcoFriendlyDeliveryClick(serviceLayerId);
    setIsModalOpen(true);
  };

  return (
    <>
      <Typography
        variant="body2"
        component="div"
        role="button"
        onClick={clickHandler}
        sx={{ cursor: 'pointer', my: 0.5 }}
      >
        {formatMessage(ecoFriendlyDeliveryMessages.co2FriendlyText, {
          flag: (
            <Flag
              sx={{ top: '-0.1em' }}
              type="sustainability"
              text={formatMessage(ecoFriendlyDeliveryMessages.co2FriendlyFlag)}
            />
          ),
        })}
      </Typography>
      <Dialog
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        title={formatMessage(ecoFriendlyDeliveryMessages.dialogTitle)}
        dialogContent={serviceLayer}
        displayTrackingProps={{ category: 'dv-service-layer-eco-friendly-delivery' }}
      />
    </>
  );
};
