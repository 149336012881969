import { Dialog as MuiDialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import type { DialogProps as MuiDialogProps, SxProps } from '@mui/material';
import {
  useTracking,
  type GlycerinDisplayBasePayload,
  type GTMEventGlycerinDisplayOverlay,
} from '@packages/tracking';
import { type ReactNode } from 'react';
import { useDeviceType } from '../../hooks/useDeviceType';
import { CloseIconButton } from '../CloseIconButton';

export interface DialogProps extends Omit<MuiDialogProps, 'children'> {
  /** Title to display at the top of the dialog */
  title: string;
  /** Handles click on close icon-button and click on backdrop */
  handleClose?: () => void;
  /** Dialog content */
  dialogContent: ReactNode;
  /** Dialog content */
  dialogContentSxProps?: SxProps;
  /** Content to display at the bottom of the dialog. Multiple elements do not need wrapper. Gap is set automatically */
  dialogActions?: ReactNode;
  /**
   * Maximum width of dialog
   * 'l' equals 600px
   * 'xl' equals 800px
   * @default 'l'
   */
  size?: 'l' | 'xl';
  displayTrackingProps?: GlycerinDisplayBasePayload | null;
}

/** Composed Dialog component with title, content and optional actions area. Based on MUI Dialog: https://mui.com/material-ui/react-dialog/ */
export const Dialog = ({
  title,
  handleClose,
  dialogContent,
  dialogActions,
  size = 'l',
  dialogContentSxProps,
  displayTrackingProps,
  open,
  fullScreen,
  ...props
}: DialogProps) => {
  const { isDesktop } = useDeviceType();
  const dispatchGTMEvent = useTracking();

  if (open && displayTrackingProps !== null) {
    dispatchGTMEvent<GTMEventGlycerinDisplayOverlay>({
      event: 'DisplayOverlay',
      DisplayOverlayData: {
        ...(displayTrackingProps ?? {}),
        category: displayTrackingProps?.category ?? 'display-overlay',
        label: displayTrackingProps?.label ?? title,
      },
    });
  }

  const getMaxWidth = () => {
    if (fullScreen) {
      return 'none';
    }

    if (!fullScreen && size === 'l') {
      return 600;
    }
    return 800;
  };

  return (
    <MuiDialog
      PaperProps={{
        sx: {
          position: 'relative',
          maxWidth: getMaxWidth(),
          width: '100%',
        },
      }}
      {...props}
      open={open}
      fullScreen={fullScreen || !isDesktop}
      onBackdropClick={handleClose}
      data-testid="dialog-wrapper"
      disableEscapeKeyDown={false}
      onClose={handleClose}
    >
      {/* padding-right is set to prevent long title to be covered by the absolute positioned close icon button */}
      <DialogTitle sx={{ position: 'relative', pr: 6 }}>
        {title}
        <CloseIconButton
          sx={{ position: 'absolute', top: '50%', right: 5, transform: 'translateY(-50%)' }}
          onClick={handleClose}
        />
      </DialogTitle>
      <DialogContent sx={{ p: 0, bgcolor: 'grey.light', ...dialogContentSxProps }}>
        {dialogContent}
      </DialogContent>
      {dialogActions && (
        <DialogActions sx={{ px: 2, py: 2, bgcolor: 'grey.light' }}>{dialogActions}</DialogActions>
      )}
    </MuiDialog>
  );
};
