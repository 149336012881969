import { usePathname, useSearchParams } from 'next/navigation';

import { buildUrl } from '../utils/buildUrl';
import { normalizeInternalPathname } from '../utils/normalizeInternalPathname';

/**
 * Gets the full URL (without domain) with support for both pages router and app router, as a companion to `usePathname` and `useSearchParams`.
 *
 * By default returns the URL with no trailing slash, unless `trailingSlash` is set to `true`. Always returns a slash for the root URL, never an empty string, matching default behavior of `location.pathname`.
 *
 * Includes full normalization, for details see `normalizeInternalPathname`.
 *
 * @example
 * useUrl()
 * // no trailing slash, single query param
 * '/foo/bar?a=1'
 * @example
 * useUrl(true)
 * // trailing slash, array query param
 * '/foo/bar/?a=1&b=2&b=3&b=4'
 * @example
 * useUrl()
 * // root, always a non-empty string
 * '/'
 */
export const useUrl = (trailingSlash = false) => {
  const pathname = usePathname() ?? '/'; // null check is necessary because in pages/app router compatibility mode, pathname can be null
  const searchParams = useSearchParams();

  const normalizedPathname = normalizeInternalPathname(pathname, { trailingSlash });

  return buildUrl({ pathname: normalizedPathname, searchParams });
};
