import type { SxProps } from '@mui/material';
import deepmerge from 'deepmerge';
import { defaultStyle, disabledStyle, selectedStyle } from '../styles';

const elevationLevel4: SxProps = {
  ':focus': {
    boxShadow: 4,
  },
  ':hover': {
    boxShadow: 4,
  },
};

const mergedDefaultStyle = deepmerge<SxProps>(defaultStyle, elevationLevel4);

const defaultStyles: SxProps = {
  borderRadius: '8px',
  color: 'text.dark',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  padding: '1px',
};

export const defaultTileStyle: SxProps = {
  ...defaultStyles,
  ...mergedDefaultStyle,
  boxShadow: 1,
};

const mergedSelectedStyle = deepmerge<SxProps>(selectedStyle, elevationLevel4);

export const selectedTileStyle: SxProps = {
  ...defaultStyles,
  ...mergedSelectedStyle,
  borderWidth: '2px',
  boxShadow: 4,
  p: 0,
};

export const disabledTileStyle: SxProps = {
  ...defaultStyles,
  ...disabledStyle,
  textDecoration: 'line-through',
};

export const selectedDisabledTileStyle: SxProps = {
  ...defaultStyles,
  ...mergedSelectedStyle,
  ':hover': {
    textDecoration: 'line-through',
  },
  color: 'text.darkTransparent',
  textDecoration: 'line-through',
  borderWidth: '2px',
  boxShadow: 4,
  p: 0,
};
