import type { TestRuleset } from './types';

export const isTestRuleset = (data: unknown): data is NonNullable<TestRuleset> =>
  typeof data === 'object' &&
  data !== null &&
  'participant' in data &&
  typeof data.participant === 'string' &&
  'variant' in data &&
  typeof data.variant === 'object' &&
  data.variant !== null &&
  Object.values(data.variant).every((x) => typeof x === 'string');
