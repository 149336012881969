import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgPlus32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M16 6a1 1 0 0 1 1 1v8h8a1 1 0 0 1 0 2h-8v8a1 1 0 0 1-2 0v-8H7a1 1 0 0 1 0-2h8V7a1 1 0 0 1 1-1" className="plus-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgPlus32);
export default Memo;