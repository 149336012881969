/**
 * method to get a part of the sku
 *
 * @param {string} sku - the sku
 * @param {'OrdernumberAndPromotion' | 'OrderNumberPromotionAndSize' | 'Size' | 'MasterStyleCommunicationKey'} [part = OrdernumberAndPromotion] - which part should be extracted
 * @returns {string} extracted part of the sku
 */

type OrdernumberAndPromotion = 'OrdernumberAndPromotion';
type ArticleNumber = 'ArticleNumber';
type OrderNumberPromotionAndSize = 'OrderNumberPromotionAndSize';
type ArticleNumberAndSize = 'ArticleNumberAndSize';
type Size = 'Size';
type MasterStyleCommunicationKey = 'MasterStyleCommunicationKey';
type PartsAsObject = 'PartsAsObject';

type PartTypes =
  | OrdernumberAndPromotion
  | ArticleNumber
  | OrderNumberPromotionAndSize
  | ArticleNumberAndSize
  | Size
  | MasterStyleCommunicationKey
  | PartsAsObject;

type GetSkuPartReturn<T extends PartTypes> = T extends PartsAsObject
  ? {
      articleNumber: string | null;
      size: string | null;
      masterStyleCommunicationKey: string | null;
    }
  : string;

export function getSkuPart<T extends PartTypes>(sku: string, part?: T): GetSkuPartReturn<T> {
  switch (part) {
    case 'PartsAsObject' as PartsAsObject: {
      const splitted = sku.split('-');
      return {
        articleNumber: splitted[0] || null,
        size: splitted[1] || null,
        masterStyleCommunicationKey: splitted[2] || null,
      } as GetSkuPartReturn<T>;
    }
    case 'OrderNumberPromotionAndSize':
    case 'ArticleNumberAndSize': {
      return sku.split('-').slice(0, 2).join('-') as GetSkuPartReturn<T>;
    }
    case 'Size': {
      return sku.split('-').slice(1, 2).join() as GetSkuPartReturn<T>;
    }
    case 'MasterStyleCommunicationKey': {
      return sku.split('-').slice(2, 3).join() as GetSkuPartReturn<T>;
    }
    case 'OrdernumberAndPromotion':
    case 'ArticleNumber':
    default: {
      return sku.split('-').slice(0, 1).join() as GetSkuPartReturn<T>;
    }
  }
}
