/* @jsxImportSource react */

import { LinkedLogo } from '../Toolbar/LinkedLogo';
import { SlotsMinimalLoading } from '../Toolbar/Slots/SlotsMinimal.loading';
import { MinimalHeaderLayout } from './layout';

export const MinimalHeaderFallback = () => (
  <MinimalHeaderLayout>
    <LinkedLogo />
    <SlotsMinimalLoading />
  </MinimalHeaderLayout>
);
