/* @jsxImportSource react */

import type { FC } from 'react';

import { MinimalHeaderFallback } from './MinimalHeader/loading';
import { DefaultHeaderFallback } from './DefaultHeader/loading';

export type HeaderFallbackProps = {
  isMinimal?: boolean;
};

export const HeaderLoading: FC<HeaderFallbackProps> = ({ isMinimal }) =>
  isMinimal ? <MinimalHeaderFallback /> : <DefaultHeaderFallback />;
