/* istanbul ignore file */
import { defineMessages } from 'react-intl';

export const priceMessages = defineMessages({
  priceUVP: {
    id: 'price.uvp',
    defaultMessage: 'UVP:',
  },
  priceDiscount: {
    id: 'price.discount',
    defaultMessage: 'statt',
  },
  dicountLabel: {
    id: 'price.discount.label',
    defaultMessage: 'Sie sparen',
  },
});
