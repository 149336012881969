import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWishlist32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M27.378 8.483c-1.886-4.117-7.239-4.796-10.62-.866L16 8.498l-.758-.881c-3.381-3.93-8.735-3.251-10.62.866C2.373 13.394 5.872 20.608 16 27.78c10.128-7.172 13.627-14.386 11.378-19.297M15.242 6.312h.145c.209.203.414.42.613.652.199-.232.404-.449.613-.652h.145l-.066-.076C24.953-1.601 40.02 12.398 16 29-8.021 12.398 7.047-1.601 15.308 6.236z" /></SvgIcon>;
};
const Memo = memo(SvgWishlist32);
export default Memo;