import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgAppIcon = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" sx={{
    ...sx
  }} {...other}><path fill="#A9003D" d="M848.984 169.748a33.688 33.688 0 0 0-9.87-24.874 33.773 33.773 0 0 0-24.904-9.858h-57.275s-92.05 0-92.05 122.583v278.876c.095 23.8-3 47.506-9.205 70.485a156.226 156.226 0 0 1-30.683 59.248 141.111 141.111 0 0 1-52.161 41.883 170.982 170.982 0 0 1-76.708 15.323c-32.73 0-58.298-7.15-76.708-22.474a136.89 136.89 0 0 1-39.888-55.162 221.44 221.44 0 0 1-15.342-70.485 541.773 541.773 0 0 1-3.068-68.442V169.748a33.688 33.688 0 0 0-9.87-24.874 33.773 33.773 0 0 0-24.905-9.858H268.05s-92.05 0-92.05 122.583v341.189c.024 36.9 4.837 73.64 14.319 109.303a255.257 255.257 0 0 0 46.025 92.959 233.158 233.158 0 0 0 83.867 64.356A302.04 302.04 0 0 0 444.989 888.9c61.366 0 110.46-13.28 146.256-40.861a254.43 254.43 0 0 0 79.777-96.024h1.022s2.046 0 2.046 26.56v57.205a33.688 33.688 0 0 0 9.87 24.875 33.773 33.773 0 0 0 24.904 9.857h52.162s87.958 0 87.958-122.583z" /></SvgIcon>;
};
const Memo = memo(SvgAppIcon);
export default Memo;