import type { QuantitySelectionProps } from '../QuantitySelection';

type GetValidQuantityPropsDefaults = {
  currentQuantity: number;
  step: NonNullable<QuantitySelectionProps['step']>;
};

export type GetValidQuantityProps =
  | (GetValidQuantityPropsDefaults & {
      type: 'increase';
      max: NonNullable<QuantitySelectionProps['max']>;
    })
  | (GetValidQuantityPropsDefaults & {
      type: 'decrease';
      min: NonNullable<QuantitySelectionProps['min']>;
    });

export const getValidQuantity = (props: GetValidQuantityProps): number => {
  switch (props.type) {
    case 'increase':
      if (props.currentQuantity + props.step > props.max) {
        return props.max;
      }
      return props.currentQuantity + props.step;
    case 'decrease':
      if (props.currentQuantity - props.step < props.min) {
        return props.min;
      }
      return props.currentQuantity - props.step;
    default:
      throw new Error('Please use a correct type.');
  }
};
