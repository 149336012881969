import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { GetGlycerinAvailabilityFragmentFragmentDoc } from '@packages/gql/generated/shopping/GetGlycerinAvailabilityFragmentFragmentDoc';
import { GlycerinAvailability } from '@packages/tracking';

/* GraphQL */ `
  fragment GetGlycerinAvailabilityFragment on Availability {
    message
    state
  }
`;

export type GetGlycerinAvailabilityProps = FragmentType<
  typeof GetGlycerinAvailabilityFragmentFragmentDoc
>;

export const getGlycerinAvailability = (availability: GetGlycerinAvailabilityProps) => {
  const { state, message } = getFragmentData(
    GetGlycerinAvailabilityFragmentFragmentDoc,
    availability,
  );

  // set the default to UNKNOWN
  const glycerinAvailability: {
    availability: GlycerinAvailability;
    estimatedDelivery: string | undefined;
  } = {
    availability: GlycerinAvailability.UNKNOWN,
    estimatedDelivery: undefined,
  };

  // check state and return correct mapping
  switch (state) {
    case 'AVAILABLE':
      glycerinAvailability.availability = GlycerinAvailability.IMMEDIATELY;
      glycerinAvailability.estimatedDelivery = message;
      return glycerinAvailability;
    case 'NOT_YET_AVAILABLE':
      glycerinAvailability.availability = GlycerinAvailability.NOTIMMEDIATELY;
      glycerinAvailability.estimatedDelivery = message;
      return glycerinAvailability;
    case 'NOT_AVAILABLE':
      glycerinAvailability.availability = GlycerinAvailability.UNAVAILABLE;
      glycerinAvailability.estimatedDelivery = undefined;
      return glycerinAvailability;
    case 'UNSELECTED':
    default:
      return glycerinAvailability;
  }
};
