import { useIsBot } from '@packages/tracking';
import { getCanonicalUrl } from '@packages/utilities';

/**
 * A hook that returns a function that determines the target URL based on the bot status.
 * If the user agent is a bot, the function returns the canonical URL, otherwise it returns the original URL.
 *
 * @returns {Function} A function that accepts a URL as an argument and returns the target URL based on the bot status.
 */
export const useAssignLinkTargetBasedOnBotStatus = (): Function => {
  const isBot = useIsBot();
  return (url: string): string => (isBot ? getCanonicalUrl(url) : url);
};
