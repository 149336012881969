import useSWR from 'swr';

import type { TestRuleset } from './useAbTesting/types';
import { useAbTesting } from './useAbTesting/useAbTesting';

/**
 * Enroll the user in an A/B test and return the test ruleset if the test exists.
 *
 * Can be safely called from multiple sites without leading to enrollment race conditions.
 *
 * @param testId the name/id of the test
 */
export const useAbTest = (testId: string): TestRuleset | undefined => {
  const { getTestRuleset } = useAbTesting();

  const { data } = useSWR(`abtest-${testId}`, () => getTestRuleset(testId));

  return data;
};
