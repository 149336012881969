'use client';

import { useContext, createContext, useReducer, useMemo, type ReactNode, useEffect } from 'react';
import { useUrl } from '../../hooks/useUrl';

export type ToastProps = {
  key?: number;
  message?: string;
  duration?: number;
  severity?: 'success' | 'info' | 'warning' | 'error';
  title?: string;
  onClose?: () => void;
};

export type ActionType = {
  type: 'add' | 'remove' | 'init';
  payload?: ToastProps;
};

export type SnackbarContextType = {
  toasts: ToastProps[];
  addToast: (toast: ToastProps) => void;
  removeToast: (key: ToastProps['key']) => void;
};

const getCurrentState = ({ state }: { state: ToastProps[] }): ToastProps[] => {
  const maxLength = 3;
  const firstPosition = state[0]?.onClose;
  const secondPosition = state[1]?.onClose;
  const thirdPosition = state[2]?.onClose;

  if (state.length < maxLength) {
    return state;
  }

  if (!firstPosition && secondPosition) {
    return state.filter((_, index) => index !== 1);
  }

  if (!firstPosition && !secondPosition && thirdPosition) {
    return state.filter((_, index) => index !== 2);
  }

  if (!firstPosition && !secondPosition && !thirdPosition) {
    return state;
  }

  return state.slice(1);
};

const reducer = (state: ToastProps[], action: ActionType) => {
  switch (action.type) {
    case 'add': {
      const currentState = getCurrentState({ state });
      return [...currentState, { ...action.payload }];
    }
    case 'remove':
      return state.filter((toast) => toast.key !== action.payload?.key);
    case 'init':
      return [];
    default:
      throw new Error();
  }
};

const SnackbarContext = createContext<SnackbarContextType>({
  toasts: [],
  addToast: () => {},
  removeToast() {},
});

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [toasts, dispatch] = useReducer(reducer, []);
  const asPath = useUrl();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => toasts && dispatch({ type: 'init' }), [asPath]);

  const addToast = (toast: ToastProps) => {
    const key = Date.now();

    dispatch({ type: 'add', payload: { ...toast, key } });
  };

  const removeToast = (key: ToastProps['key']) => dispatch({ type: 'remove', payload: { key } });

  const value = useMemo(
    () => ({
      toasts,
      addToast,
      removeToast,
    }),
    [toasts],
  );

  return <SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>;
};

export const useSnackbar = () => useContext(SnackbarContext);
