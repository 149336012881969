import type { FC } from 'react';
import type { TypographyProps } from '@packages/shared';
import { Box, Typography, searchParamsToObject, useI18n } from '@packages/shared';
import type { SxProps } from '@mui/material';
import { mergeQueryParameters } from '@packages/shared/src/utils/mergeQueryParameters';
import { useSearchParams, useRouter } from 'next/navigation';
import type { ProductAvailability } from './ProductAvailabilityOverlay.types';

export type ProductAvailabilityOverlayProps = {
  // Product availability data
  availability: ProductAvailability;
  // Product variation name
  variationName: string;
  // Product variation alternate link for alternate text
  alternateLink: string;
  // Provide a custom class name to the root element to control the style
  className?: string;
  /** handler triggered when the product link is clicked */
  onProductLinkClick?: () => void;
};

const containerStyles: SxProps = {
  padding: 1,
  textAlign: 'center',
  background: 'rgba(255, 255, 255, 0.9)',
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  transition: 'opacity 0.5s ease',
};

/**
 * ProductAvailabilityOverlay shows the availability of a product variation
 * This can include a list of available sizes or an alternate text
 * */
export const ProductAvailabilityOverlay: FC<ProductAvailabilityOverlayProps> = ({
  availability,
  variationName,
  alternateLink,
  className,
  onProductLinkClick,
}) => {
  const { localizeUrl, language } = useI18n();
  const { push } = useRouter();
  const searchParams = useSearchParams();

  const handleClickAndNavigateTo =
    (productUrl: string): TypographyProps['onClick'] =>
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      onProductLinkClick?.();

      const finalProductUrl = mergeQueryParameters(productUrl, searchParamsToObject(searchParams));
      push(localizeUrl(finalProductUrl, language));
    };

  return (
    <Box sx={containerStyles} className={className} data-testid="product-overlay">
      <Typography sx={{ marginBottom: 0.25, fontSize: '0.75rem' }}>{variationName}</Typography>
      {availability.alternateText && alternateLink ? (
        // <Link fontSize="0.75rem" underline="hover" color="inherit" href={alternateLink} onClick={onProductLinkClick}>
        //   {availability.alternateText}
        // </Link>
        <Typography
          sx={{
            margin: 0.3,
            fontSize: '0.75rem',
            display: 'inline-block',
            '&:hover': { textDecoration: 'underline' },
          }}
          onClick={handleClickAndNavigateTo(alternateLink)}
        >
          {availability.alternateText}
        </Typography>
      ) : (
        availability.values.map((item) => (
          // <Link
          //   title={item.message}
          //   underline="hover"
          //   color="inherit"
          //   sx={{ margin: 0.3, fontSize: '0.75rem', display: 'inline-block' }}
          //   key={item.availabilityName}
          //   href={item.variationProductUrl}
          //   onClick={onProductLinkClick}
          // >
          //   {item.availabilityName}
          // </Link>
          //
          // TODO remove after NJS-602, placeholder for invalid html issue
          <Typography
            title={item.message}
            sx={{
              margin: 0.3,
              fontSize: '0.75rem',
              display: 'inline-block',
              '&:hover': { textDecoration: 'underline' },
            }}
            key={item.availabilityName}
            onClick={handleClickAndNavigateTo(item.variationProductUrl)}
          >
            {availability.values.length > 1 ? item.availabilityName : item.message}
          </Typography>
        ))
      )}
    </Box>
  );
};
