import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { Card, CardContent, CardHeader, Stack } from '@packages/shared';
import { useIntl } from 'react-intl';
import { DownloadsCardFragmentFragmentDoc } from '@packages/gql/generated/shopping/DownloadsCardFragmentFragmentDoc';
import { DownloadItem } from '../DownloadItem';
import { downloadsCardMessages } from './messages';

/* GraphQL */ `
  fragment DownloadsCardFragment on Product {
    downloads {
      ...DownloadItemFragment
    }
  }
`;
export type DownloadsCardProps = {
  data: FragmentType<typeof DownloadsCardFragmentFragmentDoc>;
};

/**
 * DownloadsCard component displays all available downloads
 * */
export const DownloadsCard = ({ data }: DownloadsCardProps) => {
  const { formatMessage } = useIntl();
  const { downloads } = getFragmentData(DownloadsCardFragmentFragmentDoc, data);

  if (!downloads.length) {
    return null;
  }

  return (
    <Card>
      <CardHeader
        title={formatMessage(downloadsCardMessages.Header)}
        sx={{ borderBottom: 1, borderColor: 'grey.main', py: 1, px: 2 }}
      />
      <CardContent>
        <Stack spacing={1.5}>
          {downloads.map((download, idx) => (
            <DownloadItem
              data={download}
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};
