import { getErrorCookieName, getParticipantCookieName, getTestCookieName } from './cookieNames';

/**
 * Build all necessary cookies for a given test ruleset, including the short-lived full test cookie and the long-lived participant cookie.
 */
export const buildTestCookies = ({
  testId,
  ruleset: { participant, variant },
}: {
  testId: string;
  ruleset: {
    participant: string;
    variant: Record<string, string>;
  };
}) => {
  // expiry date once for in a day and once in 30 days
  const now = new Date().valueOf();

  // NOTE not really a day, but 24h, which will result in +-1 hour in local time when crossing DST boundaries, which hopefully should not be an issue
  // Using the native Date.setDate is OS-timezone-dependent
  // dayjs.add fails to account for DST boundaries (see https://github.com/iamkun/dayjs/issues/1271)
  // To simplify this, we just work fully in UTC
  const day = 24 * 60 * 60 * 1000;

  const testCookie = {
    name: getTestCookieName(testId),
    value: JSON.stringify({ participant, variant }),
    path: '/',
    expires: new Date(now + 1 * day),
  };

  const participantCookie = {
    name: getParticipantCookieName(testId),
    value: participant,
    path: '/',
    expires: new Date(now + 90 * day),
  };

  return [testCookie, participantCookie];
};

/**
 * build an error cookie for a given test id, signalling that enrollment has failed and should not be retried for a while
 */
export const buildErrorCookie = ({ testId }: { testId: string }) => {
  const now = new Date().valueOf();
  const hour = 60 * 60 * 1000;

  return {
    name: getErrorCookieName(testId),
    value: 'error fetching ruleset',
    path: '/',
    expires: new Date(now + 1 * hour),
  };
};
