export const getGlycerinOrigin = (
  refSearchParam: string | null,
): { origin: string | undefined } => {
  if (refSearchParam) {
    return {
      origin: refSearchParam,
    };
  }

  /**
   * get hash params.
   * example:
   * URL is:                        ..?searchParam=test#ref=hash&test=1
   * window.location.hash then is:  #ref=hash&test=1
   */
  if (typeof window !== 'undefined') {
    // substring to remove the '#'
    const hashparams = new URLSearchParams(window.location.hash.substring(1));

    if (hashparams.has('ref')) {
      return {
        origin: hashparams.get('ref') || undefined,
      };
    }
  }

  return { origin: undefined };
};
