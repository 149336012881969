import type { AddToBasketTrackingFragmentFragment } from '@packages/gql/generated/shopping/graphql';

export function getAddToBasketVariantString(
  dimensions: AddToBasketTrackingFragmentFragment['dimensions'],
) {
  const variant: string[] = [];
  dimensions?.forEach(({ values }) => {
    values.forEach(({ isSelected, displayText }) => {
      if (isSelected === true && displayText) {
        variant.push(displayText);
      }
    });
  });

  return variant.join(', ');
}
