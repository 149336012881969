import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgMinus32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M7 17a1 1 0 0 1 0-2h18a1 1 0 0 1 0 2z" className="minus-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgMinus32);
export default Memo;