import type { WishlistLineItemsFragmentFragment } from '@packages/gql/generated/shopping/graphql';
import { Button, CircularProgress, useSnackbar } from '@packages/shared';
import type { MessageDescriptor } from 'react-intl';
import { useIntl } from 'react-intl';
import { useAuthMutation } from '@packages/gql';
import { bridge } from '@empiriecom/mybuy-session/Bridge';
import { EditInWishlistFragmentFragmentDoc } from '@packages/gql/generated/shopping/EditInWishlistFragmentFragmentDoc';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { EditInWishlistDocument } from '@packages/gql/generated/shopping/EditInWishlistDocument';
import { editInWishlistMessage } from './messages';

/* GraphQL */ `
  fragment EditInWishlistFragment on Product {
    sku
    availability {
      isOrderable
      state
    }
  }
`;

export interface EditInWishlistButtonProps {
  data: FragmentType<typeof EditInWishlistFragmentFragmentDoc>;
  prevSku: WishlistLineItemsFragmentFragment['sku'];
}

export const EditInWishlistButton: React.FC<EditInWishlistButtonProps> = ({
  prevSku,
  data: productData,
}) => {
  const product = getFragmentData(EditInWishlistFragmentFragmentDoc, productData);
  const {
    sku: newSku,
    availability: { state, isOrderable },
  } = product;
  const { formatMessage, locale } = useIntl();
  const { addToast } = useSnackbar();
  const [editInWishlistState, executeEditInWishlistMutation] =
    useAuthMutation(EditInWishlistDocument);
  const editWishlistHandler = async () => {
    if (prevSku === newSku) return; // no change
    if (editInWishlistState.fetching || !prevSku || !newSku) return;

    try {
      const addToWishlistResult = await executeEditInWishlistMutation({
        locale,
        oldSku: prevSku,
        newSku,
        // if addOnlyIfFound is true, the newSku will be added to the wishlist only if the oldSku is found
        // if addOnlyIfFound is false, the newSku will be added to the wishlist regardless of the oldSku ensuring that we have a product in the wishlist
        addOnlyIfFound: false,
        unspecified: state === 'UNSELECTED',
      });

      if (
        addToWishlistResult.error ||
        addToWishlistResult.data?.replaceInWishlist.success === false
      ) {
        throw new Error('Error during mutation execution');
      }
      if (bridge?.updateWishlist) bridge.updateWishlist();
      addToast({
        message: formatMessage(editInWishlistMessage.successMessage),
        severity: 'success',
        duration: 5000,
      });
    } catch (error) {
      addToast({
        message: formatMessage(editInWishlistMessage.failedMessage),
        severity: 'error',
        duration: 5000,
      });
    }
  };
  const isAvailable = state !== 'UNSELECTED' ? isOrderable : true;
  const isLoading = editInWishlistState.fetching;
  const customButtonText = ((): MessageDescriptor => {
    if (!isAvailable) return editInWishlistMessage.soldOut;
    return editInWishlistMessage.buttonText;
  })();
  return (
    <Button
      sx={{
        whiteSpace: 'nowrap',
        minWidth: '15rem',
      }}
      size="large"
      onClick={editWishlistHandler}
      color="primary"
      fullWidth
      data-testid="edit-wishlist-action"
      disabled={!isAvailable || isLoading}
    >
      {isLoading ? <CircularProgress /> : formatMessage(customButtonText)}
    </Button>
  );
};
