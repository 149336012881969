import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgTracking32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M11.717 3.55 24.56 9.731l4.73-2.186a.5.5 0 0 1 .703.372L30 8v15.813a.5.5 0 0 1-.298.457l-14 6.187a.522.522 0 0 1-.248.041.468.468 0 0 1-.169-.047l-13-6.187A.5.5 0 0 1 2 23.813V8a.5.5 0 0 1 .723-.448l9.749 4.853a.5.5 0 1 1-.446.895L3 8.807v14.69l12 5.711V14.78l-.547-.272a.5.5 0 0 1 .445-.895l.61.302 2.835-1.31a.5.5 0 0 1 .619.167l.045.078a.5.5 0 0 1-.244.663L16 14.79v14.442l13-5.745V8.782l-4.037 1.866a.499.499 0 0 1 .037.19v5.73a.5.5 0 0 1-.313.463l-3.555 1.433a.5.5 0 0 1-.687-.464v-5.422L8.275 6.447a.5.5 0 0 1 .45-.894l11.909 6 .058-.033 2.69-1.244-12.099-5.825zM24 11.091l-2.555 1.181v4.986L24 16.23zm-7.3-8.545L2.694 8.461a.5.5 0 0 1-.388-.922l14.21-6a.5.5 0 0 1 .407.008l12.79 6a.5.5 0 1 1-.425.906z" className="tracking-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgTracking32);
export default Memo;