import { useEffect, useState } from 'react';
import { parseCookies, setCookie } from 'nookies';

export type CookieCategory = 'C0001' | 'C0003' | 'C0002' | 'C0004' | 'C0005';

declare global {
  // eslint-disable-next-line no-var, vars-on-top
  var OnetrustActiveGroups: string;
}

/** The cookie expires one year AFTER the current date. */
const getCookieExpiry = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);
  return date;
};

const getValidCategoriesFromGlobal = () => {
  const categories = global.OnetrustActiveGroups.split(',') as CookieCategory[];

  return categories.filter((c) => ['C0001', 'C0003', 'C0002', 'C0004', 'C0005'].includes(c));
};

/**
 * This hook parses the cookies of the current document and checks whether consent has been granted for the specified cookie category.
 * If the OTConsentCategoriesAccepted cookie does not exist, the global variable OnetrustActiveGroups is checked.
 * The hook sets the OTConsentCategoriesAccepted cookie according to the user's consent, with changes triggered by the consent.onetrust event.
 * @param category is the specific type of cookie category for which consent is checked.
 * @returns a boolean indicating whether consent has been given for the provided cookie category.
 */
export const useCookieConsent = (category: CookieCategory) => {
  const cookies = parseCookies();

  let initialAllowedCategories: CookieCategory[] = [];
  let categoryExistsInCookie = false;

  try {
    let parsedCookie: CookieCategory[] = [];

    if (typeof cookies.OTConsentCategoriesAccepted !== 'undefined') {
      try {
        // First try to interpret the cookie value as JSON
        parsedCookie = JSON.parse(cookies.OTConsentCategoriesAccepted || '[]') as CookieCategory[];
      } catch (jsonError) {
        // If the cookie value cannot be interpreted as JSON, we assume that it is a comma-separated string like 'C0001,C0002,C0003'
        parsedCookie = cookies.OTConsentCategoriesAccepted.split(',') as CookieCategory[];
      }

      initialAllowedCategories = parsedCookie;
      categoryExistsInCookie = parsedCookie.includes(category);
    } else if (global.OnetrustActiveGroups) {
      // If the cookie was not defined, use the OnetrustActiveGroups global variable
      initialAllowedCategories = getValidCategoriesFromGlobal();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.error('Error parsing OTConsentCategoriesAccepted cookie: ', error);
  }

  // Initialize the allowedCategories state with the initial categories
  const [allowedCategories, setAllowedCategories] =
    useState<CookieCategory[]>(initialAllowedCategories);

  // This useEffect is called IF the "OTConsentCategoriesAccepted" cookie is CHANGED.
  // It checks if the OnetrustActiveGroups global variable is defined and if the "OTConsentCategoriesAccepted" cookie does NOT exist.
  // If it does, it creates the cookies based on the categories in the "OnetrustActiveGroups" from the global variable.
  useEffect(() => {
    if (
      typeof global.OnetrustActiveGroups !== 'undefined' &&
      typeof cookies.OTConsentCategoriesAccepted === 'undefined'
    ) {
      const validCategories = getValidCategoriesFromGlobal();

      setCookie(null, 'OTConsentCategoriesAccepted', JSON.stringify(validCategories), {
        path: '/',
        expires: getCookieExpiry(),
      });
    }
  }, [cookies.OTConsentCategoriesAccepted]);

  // This useEffect is called IF the categoryExistsInCookie variable CHANGES.
  // It adds an event listener to the 'consent.onetrust' event IF the OTConsentCategoriesAccepted cookie does NOT EXIST.
  // The event listener updates the OTConsentCategoriesAccepted cookie and the allowedCategories state IF the 'consent.onetrust' event is fired.
  useEffect(() => {
    if (!categoryExistsInCookie) {
      const onChange = ({ detail = [] }: CustomEvent<CookieCategory[]>) => {
        setCookie(null, 'OTConsentCategoriesAccepted', JSON.stringify(detail), {
          path: '/',
          expires: getCookieExpiry(),
        });

        setAllowedCategories(detail);
      };

      window.addEventListener('consent.onetrust', onChange as EventListener);

      return () => window.removeEventListener('consent.onetrust', onChange as EventListener);
    }
    return () => {};
  }, [categoryExistsInCookie]);

  return categoryExistsInCookie || allowedCategories.includes(category);
};
