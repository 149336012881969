import type { GenericTrackingEvent } from '@packages/tracking';

export const onLoad = (
  eventName: string,
  dispatchGTMEvent: <T extends GenericTrackingEvent<any>>(event: T) => void,
  trackingInfo: {
    onloadTrackingName: string;
    onloadTrackingKey: string;
    onloadTrackingValue: string;
  },
): void => {
  const trackingEventValue: any = {};
  trackingEventValue[trackingInfo.onloadTrackingKey] = trackingInfo.onloadTrackingValue;
  dispatchGTMEvent({
    event: eventName,
    eventLabel: trackingInfo.onloadTrackingName,
    eventValue: trackingEventValue,
  });
};

/**
 * Executes the passed callback function after it finds the checking function to be ready
 * @param toCheck Function that is called when checking if callback can be executed
 * @param callback Callback function that is called when toCheck returns true
 */
export const waitUntilReady = (toCheck: () => boolean, callback: () => void) => {
  const start = Date.now();
  const timeout = 20000;

  function checkForReadyness() {
    if (toCheck()) {
      callback();
    } else if (Date.now() - start < timeout) {
      setTimeout(checkForReadyness, 50);
    }
  }

  checkForReadyness();
};
