import { defineMessages } from 'react-intl';

export const efficiencyFlagListMessages = defineMessages<string>({
  WETGRIP: {
    defaultMessage: 'Nasshaftung',
    id: 'efficiencyflag.dialog.title.wetgrip',
  },
  FUELEFFICIENCY: {
    defaultMessage: 'Kraftstoffeffizienz',
    id: 'efficiencyflag.dialog.title.fuelEfficiency',
  },
  EXTERNALROLLINGNOISE: {
    defaultMessage: 'Rollgeräusch',
    id: 'efficiencyflag.dialog.title.externalRollingNoise',
  },
});
