import { useEffect, useState, type FC } from 'react';
import useSWR from 'swr';
import { useAtomValue } from 'jotai';
import { usePathname } from 'next/navigation';
import { setCookie } from 'nookies';

import { useI18n, useUrl } from '@packages/shared';
import { LanguageSwitch32 } from '@packages/themes/icons';
import type { CookieOptions } from '@packages/utilities';
import { useConfig } from '@packages/utilities';
import { getFullLocaleFromConfig, localizedPathnameCacheKey } from '@packages/config/src/default';
import type { Language } from '@packages/config';

import type { SlotProps } from './Slot';
import { Slot } from './Slot';
import { languageAlternatesAtom } from './LocaleSwitchSlot.atom';

export type LocaleSwitchSlotProps = Pick<SlotProps, 'sx'>;

/** Locale Switch slot with list of helpers in popover */
export const LocaleSwitchSlot: FC<LocaleSwitchSlotProps> = ({ sx }) => {
  const [showUrl, setShowUrl] = useState(false);

  // Hide the URL on initial SSR, because the page-provided alternates are only available on the client when using the app router
  // If we show the default language-changed pathname, it will be incorrect for some routes, and possibly lead to SEO index pollution
  // With this delay, we allow the page enough time to set the atom with the correct alternates
  useEffect(() => {
    setShowUrl(true);
  }, []);

  const config = useConfig();
  const {
    i18n: { languages },
  } = useConfig();
  const { language } = useI18n();
  const pathname = usePathname();
  const url = useUrl();

  const { data: localizedPathnameFromPagesRouter } = useSWR(localizedPathnameCacheKey, {
    // prevent fetching newer data and use only stale data (https://swr.vercel.app/docs/revalidation#disable-automatic-revalidations)
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const COOKIE_OPTIONS: CookieOptions = {
    path: '/',
    maxAge: 86400, // 90 days in seconds
    secure: false, // true would be nice but this is what intershop defines,
    sameSite: 'lax',
    httpOnly: false,
  };

  const languageTitle: Partial<Record<Language, string>> = {
    fr: 'Français',
    de: 'Deutsch',
  };

  const targetLanguage: Language = languages.find((l: string) => l !== language)!;

  const languageAlternates = useAtomValue(languageAlternatesAtom);
  const localizedPathnameFromAppRouter =
    languageAlternates?.currentPathname === pathname
      ? languageAlternates?.alternateLinks?.[targetLanguage]
      : undefined;

  const href = showUrl
    ? localizedPathnameFromAppRouter || localizedPathnameFromPagesRouter || url
    : undefined;

  return (
    <Slot
      icon={<LanguageSwitch32 />}
      title={languageTitle[targetLanguage]}
      language={targetLanguage}
      href={href}
      onClick={() => {
        setCookie(null, 'PreferredLanguage', targetLanguage, COOKIE_OPTIONS);
        // Set PreferredLocale with legacy parameters for consistent behaviour with the legacy checkout.
        // Can be removed after nextjs checkout is live
        setCookie(
          null,
          'PreferredLocale',
          getFullLocaleFromConfig(targetLanguage, config).replace('-', '_'),
          {
            ...COOKIE_OPTIONS,
            domain: `.${window.document.domain}`,
          },
        );
      }}
      sx={sx}
    />
  );
};
