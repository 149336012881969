import type { QuantitySelectionProps } from '../QuantitySelection';

type GetValidValuesProps = Required<Omit<QuantitySelectionProps, 'lineItemQuantity'>>;

export const getValidValues = ({ max, min, step }: GetValidValuesProps) => {
  const roundedStep = Math.round(step);
  const roundedMin = Math.round(min);
  const roundedMax = Math.round(max);

  const validStep = roundedStep < 1 ? 1 : roundedStep;
  const validMin = roundedMin < 1 ? 1 : roundedMin;
  const validMax = roundedMax <= roundedMin ? roundedMin + validStep : roundedMax;

  return {
    step: validStep,
    min: validMin,
    max: validMax,
  };
};
