import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCheckmark32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M13.398 27.383a2.408 2.408 0 0 1-1.929-.967L4.965 17.7a2.407 2.407 0 0 1 3.857-2.879l4.409 5.908 11.01-17.46a2.406 2.406 0 0 1 4.071 2.567L15.433 26.26a2.405 2.405 0 0 1-1.941 1.121l-.095.002z" /></SvgIcon>;
};
const Memo = memo(SvgCheckmark32);
export default Memo;