/* @jsxImportSource react */

import type { FC, ReactNode } from 'react';

export type MinimalHeaderLayoutProps = {
  children?: ReactNode;
};

export const MinimalHeaderLayout: FC<MinimalHeaderLayoutProps> = ({ children }) => (
  <>
    <div className="mx-auto flex max-w-screen-xl items-center max-lg:shadow">
      <header className="flex flex-1 items-center justify-between max-lg:px-1 lg:gap-6">
        {children}
      </header>
    </div>

    <div className="border-color-grey border-b max-lg:hidden" />
  </>
);
