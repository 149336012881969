/* @jsxImportSource react */

import config from '@packages/config';

import { SlotsContainer } from './SlotsContainer.tailwind';
import { SlotLoading } from './Slot.loading';

export const SlotsMinimalLoading = () => {
  const {
    headerSlots: { payback, joe },
  } = config;

  return (
    <SlotsContainer>
      {payback && <SlotLoading className="payback" />}
      {joe && <SlotLoading />}
      <SlotLoading />
      <SlotLoading />
    </SlotsContainer>
  );
};
