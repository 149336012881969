import { keyframes } from '@mui/system';
import { useEffect } from 'react';
import { Stack, Typography, Link, Box } from '@packages/shared';
import { useConfig } from '@packages/utilities';
import type { GenericTrackingEvent } from '@packages/tracking';
import { useTracking } from '@packages/tracking';
import type { NavigationData } from '../types';
import { getNavigationCluster } from './findNode';

const fadeIn = keyframes`
  0% { opacity: 0; max-height: 0; }
  100% { opacity: 1; }
`;

const StyledLink = ({ to, children }: { to: string; children: React.ReactNode }) => {
  const dispatchGTMEvent = useTracking();
  const pathSegments = typeof to === 'string' ? to.split('/').filter((v: string) => v) : undefined;

  return (
    <Link
      sx={{
        color: 'inherit',
        textDecoration: 'none',
      }}
      href={to}
      onClick={() => {
        dispatchGTMEvent<GenericTrackingEvent>({ event: 'Layer_Navi', eventValue: pathSegments });
      }}
    >
      {children}
    </Link>
  );
};

const SubNavigationItem = ({
  displayAsPatches = false,
  bold = false,
  link = false,
  darken = false,
  children,
}: {
  displayAsPatches?: boolean;
  bold?: boolean;
  link?: boolean;
  darken?: boolean;
  children: React.ReactNode;
}) => (
  <Box
    sx={[
      {
        position: 'relative',
        p: 0.5,
        color: 'text.dark',
        '&:hover': link
          ? {
              backgroundColor: darken ? 'grey.light' : 'common.white',
              '&:before': {
                content: '""',
                position: 'absolute',
                left: '-0.25rem',
                top: 0,
                height: '100%',
                width: '0.25rem',
                backgroundColor: 'primary.main',
                borderRadius: '0.25rem 0 0 0.25rem',
              },
            }
          : {},
      },
      displayAsPatches
        ? {
            display: 'inline-block',
            my: 0,
            mx: -0.5,
          }
        : {
            display: 'block',
            transition: '0.125s ease-in-out',
            transitionProperty: 'border-color, background-color',
            willChange: 'border-color, background-color',
          },
    ]}
  >
    <Typography variant="body1" sx={[{ fontSize: '0.875rem' }, bold && { fontWeight: 'bold' }]}>
      {children}
    </Typography>
  </Box>
);

interface NavigationOverlayProps {
  data: NavigationData;
  onClose?: () => void;
}

export const NavigationOverlay = ({ data, onClose }: NavigationOverlayProps) => {
  const { clientId } = useConfig();
  const darken = clientId === 'BaurDe';
  const clusterData = getNavigationCluster(data || []);
  useEffect(() => {
    const onMainNavHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose?.();
    };
    document.addEventListener('keydown', onMainNavHandler);

    return () => {
      document.removeEventListener('keydown', onMainNavHandler);
    };
  }, [onClose]);

  return (
    <Box
      data-testid="navigation-overlay"
      sx={{
        position: 'absolute',
        top: darken ? '100%' : 'calc(100% + 0.25rem)',
        left: 'var(--background-bleed)',
        right: 'var(--background-bleed)',
        color: 'grey.light',
        boxShadow: 1,
        backgroundColor: darken ? 'grey.main' : 'grey.light',
        animation: `${fadeIn} 0.25s 0s ease-in-out`,
        py: 0,
        px: 1,
        zIndex: 'drawer',
      }}
    >
      <Stack
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'stretch',
          maxWidth: (theme) => theme.breakpoints.values.xl + parseInt(theme.spacing(), 10),
          margin: 'auto',
          overflow: 'visible',
        }}
        spacing={2}
        direction="row"
      >
        {clusterData.map((navItems) => (
          <Box
            sx={[
              {
                py: 2,
                px: 0,
                zIndex: 'drawer',
              },
              Boolean(navItems[0] && navItems[0].displayAsPatches) && {
                display: 'flex',
                justifyItems: 'baseline',
                flex: '1 1 auto',
                justifyContent: 'flex-end',
              },
            ]}
            data-testid="navigation-col"
            key={JSON.stringify(navItems)}
          >
            {navItems.map(
              ({
                uri,
                displayName = '',
                displayName_hl = '',
                children = [],
                space,
                displayAsPatches,
              }) => (
                <Box
                  sx={{
                    marginBottom: `${space || 0}rem`,
                    '> div': {
                      display: 'flex',
                      '> ul': {
                        p: 0,
                        m: 0,
                        listStyle: 'none',
                        flex: '1 1 auto',
                        ...(displayAsPatches
                          ? {
                              paddingLeft: '0.25rem',
                              display: 'flex',
                              flexWrap: 'wrap',
                              '> li': {
                                flex: '1 1 20%',
                                maxWidth: '20%',
                              },
                            }
                          : {}),
                      },
                    },
                  }}
                  key={`${uri}${displayName}${displayName_hl}`}
                >
                  {(displayName || displayName_hl) &&
                    (uri ? (
                      <StyledLink to={uri} data-testid="navigation-item">
                        <SubNavigationItem bold link>
                          {displayName || displayName_hl}
                        </SubNavigationItem>
                      </StyledLink>
                    ) : (
                      <SubNavigationItem bold>{displayName || displayName_hl}</SubNavigationItem>
                    ))}
                  <Box
                    sx={{
                      paddingTop: !displayName && !displayName_hl ? '29px' : undefined,
                    }}
                  >
                    {!!children.length && (
                      <ul>
                        {children.map((child) => (
                          <li key={(child.uri || '') + child.displayName}>
                            {child.uri ? (
                              <StyledLink to={child.uri}>
                                <SubNavigationItem link displayAsPatches={displayAsPatches}>
                                  {child.displayName}
                                </SubNavigationItem>
                              </StyledLink>
                            ) : (
                              <SubNavigationItem displayAsPatches={displayAsPatches}>
                                {child.displayName}
                              </SubNavigationItem>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </Box>
                </Box>
              ),
            )}
          </Box>
        ))}
      </Stack>
    </Box>
  );
};
