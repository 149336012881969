import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgJoe = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" sx={{
    ...sx
  }} {...other}><path fill="#d6ab53" d="M150.07 201.49c-11.81 0-22.39-5.71-29.58-14.73v1.49a29.62 29.62 0 0 0 59.23 0v-1.49c-7.26 9.02-17.85 14.73-29.65 14.73" /><path fill="#d6ab53" d="M150 0a150 150 0 1 0 150 150A150 150 0 0 0 150 0m32.74 48.29a26.93 26.93 0 1 1-26.92 26.93 26.93 26.93 0 0 1 26.92-26.93m-65.48 0a26.93 26.93 0 1 1-26.93 26.93 26.93 26.93 0 0 1 26.93-26.93M150 251.84A70.87 70.87 0 1 1 220.87 181 70.87 70.87 0 0 1 150 251.84" /></SvgIcon>;
};
const Memo = memo(SvgJoe);
export default Memo;