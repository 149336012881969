import type { AvailabilityState } from '@packages/gql/generated/shopping/graphql';

export function getIsAvailable<T extends { state: AvailabilityState }>(
  availability?: T | undefined | null,
) {
  if (!(availability && availability?.state)) return true;

  if (availability.state === 'AVAILABLE' || availability.state === 'NOT_YET_AVAILABLE') return true;

  return false;
}
