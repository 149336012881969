'use client';

import { type FC } from 'react';
import { useMouseMovementOutcome } from '@packages/abtesting';
import { DefaultHeader } from './DefaultHeader';
import { MinimalHeader } from './MinimalHeader';
import { PaceId } from './PaceId';
import { SearchHistoryUpdater } from './SearchHistoryUpdater';
import { useCopiedDynamicYieldCookie } from './useCopiedDynamicYieldCookie';
import {
  INSPIRING_SEARCH_TEST_ID,
  INSPIRING_SEARCH_V2_TEST_ID,
} from './useInspiringSearchSettings.shared';

export type HeaderProps = {
  isMinimal?: boolean;
};

export const Header: FC<HeaderProps> = ({ isMinimal }) => {
  // useAbTest('2024_04_SoFiOn'); // TODO re-add after/during SEARCH-2926, disabled for now because the test was postponed due to missing functionality on the DV

  useMouseMovementOutcome(INSPIRING_SEARCH_TEST_ID);
  useMouseMovementOutcome(INSPIRING_SEARCH_V2_TEST_ID);

  useCopiedDynamicYieldCookie();

  return (
    <>
      <PaceId />
      <SearchHistoryUpdater />

      {isMinimal ? <MinimalHeader /> : <DefaultHeader />}
    </>
  );
};
