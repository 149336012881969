import type { SxProps } from '@mui/material';

export const quantitySelectionStyles: SxProps = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  my: 1.5,
  '& > .qsLabel': {
    mx: 2,
    userSelect: 'none',
  },
  '& > .qsButton': {
    width: '1.75rem',
    height: '1.75rem',
    minHeight: '1.75rem',
  },
  '& > .qsValue': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'grey.dark',
    borderRadius: '3px',
    overflow: 'hidden',
    fontSize: '0.813rem',
    mx: 1.5,
    width: '3.5rem',
    height: ' 1.75rem',
    textAlign: 'center',
    userSelect: 'none',
  },
};
