import { useCallback, useContext } from 'react';

import { logger } from '@packages/utilities';
import { TrackingContext } from '../../components/TrackingProvider';
import type { GenericTrackingEvent } from '../../types';
import { usePageviewId } from '../../components/PageviewProvider/PageviewProvider';

const dispatchEvent = <T extends GenericTrackingEvent>(
  event: T,
  dataLayerName = 'dataLayer',
  pageViewID = '',
): void => {
  if (typeof window === 'undefined') return;

  const dataLayer = (window as { [key: string]: any })[dataLayerName];
  const isGlycerinEvent = typeof (event as any)[`${event.event}Data`] === 'object';
  const modifiedEvent = {
    ...event,
    ...(pageViewID !== '' && { pageViewID }),
    ...(isGlycerinEvent && { _clear: true }),
  };

  if (dataLayer) {
    dataLayer.push(modifiedEvent);
  } else {
    logger.warn(
      { modifiedEvent },
      `[useTracking] window.${dataLayerName} is not defined, event could not be pushed.`,
    );
  }
};

export const useTracking = (): (<T extends GenericTrackingEvent>(event: T) => void) => {
  const context = useContext(TrackingContext);
  const pageViewID = usePageviewId();
  const dataLayerName = context?.dataLayerName;

  if (context === null || typeof dataLayerName === 'undefined') {
    logger.error(
      '[useTracking]: hook was used outside of a TrackingProvider component. Events might not be tracked correctly.',
    );
  } else if (typeof window !== 'undefined' && !(window as { [key: string]: any })[dataLayerName]) {
    (window as { [key: string]: any })[dataLayerName] = [];
  }

  return useCallback(
    <T extends GenericTrackingEvent>(event: T) => {
      if (!dataLayerName) return;

      dispatchEvent(event, dataLayerName, pageViewID);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataLayerName, pageViewID],
  );
};
