import type { ReactNode } from 'react';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { Box, Typography } from '@packages/shared';
import { DimensionFragmentFragmentDoc } from '@packages/gql/generated/shopping/DimensionFragmentFragmentDoc';
import { ColorTilesDimension } from './ColorTilesDimension';
import { TilesDimension } from './TilesDimension';
import { SelectDimension } from './SelectDimension';
import type { SetSelectedVariation } from '../types/types';

/* GraphQL */ `
  fragment DimensionFragment on ProductDimension {
    axisType
    displayName
    type
    ...TilesValuesFragment
    ...ColorTilesValuesFragment
    ...SelectValuesFragment
  }
`;

export type VariationSelectProps = {
  /**
   * The GraphQL dimension data for the given product
   */
  dimensionData: FragmentType<typeof DimensionFragmentFragmentDoc>;
  onChange: SetSelectedVariation;
  selectedVariation: string | null;
  sampleOrderLink: ReactNode;
};

/**
 * VariationSelect component decides which component-type to be rendered:
 * There are multiple ways, a product and its variations can be selected (e.g. Select, Tiles, ...).
 * Depending on configuration and backend-data, this component chooses a suiting
 * display-type.
 * */
export const VariationSelect = ({
  dimensionData,
  onChange,
  selectedVariation,
  sampleOrderLink,
}: VariationSelectProps) => {
  const dimension = getFragmentData(DimensionFragmentFragmentDoc, dimensionData);

  let content = <SelectDimension dimensionData={dimension} onChange={onChange} />;

  if (dimension.axisType === 'COLOR' && dimension.type === 'TILES') {
    content = (
      <ColorTilesDimension
        dimensionData={dimension}
        onChange={onChange}
        selectedVariation={selectedVariation}
      />
    );
  }

  if (dimension.axisType !== 'COLOR' && dimension.type === 'TILES') {
    content = (
      <TilesDimension
        dimensionData={dimension}
        onChange={onChange}
        selectedVariation={selectedVariation}
      />
    );
  }
  return (
    <Box>
      <Typography variant="body1">{dimension.displayName}</Typography>
      <Box sx={{ mt: 0.5 }}>{content}</Box>
      {sampleOrderLink !== null && dimension.axisType === 'COLOR' && sampleOrderLink}
    </Box>
  );
};
