import { startTransition, useState } from 'react';
import useSWR from 'swr';

import { useDeviceType, useI18n } from '@packages/shared';
import { useConfig } from '@packages/utilities';

import type { NavigationData } from './types';
import { getNavigationDataEndpoint } from './getNavigationDataEndpoint';
import { fetchRequiredStaticJson } from '../api';

/**
 * Fetch navigation data from the API
 *
 * @returns navigation data, initially only the first few levels ("sliced"), and a function to load the full data on-demand
 */
export const useNavigationData = (): {
  data: NavigationData;
  loadFullData: () => void;
} => {
  const {
    clientId,
    navigation: { loadSliced },
  } = useConfig();

  const [sliced, setSliced] = useState(loadSliced);
  const { locale } = useI18n();
  const { isDesktop } = useDeviceType();

  const endpoint = getNavigationDataEndpoint(clientId, isDesktop ? 'web' : 'mob', locale, sliced);

  const { data } = useSWR<NavigationData>(
    endpoint,
    (url) => fetchRequiredStaticJson(url, { componentName: 'useNavigationData' }),
    {
      keepPreviousData: true,
      revalidateOnMount: true,
    },
  );

  const loadFullData = () => {
    startTransition(() => setSliced(false));
  };

  return { data: data || [], loadFullData };
};
