import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgPdf32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M25 22h-2v1h2v1h-2v2h-1v-5h3zm-6.5 4H17v-5h1.5a2.5 2.5 0 1 1 0 5m-4-2H13v2h-1v-5h2.5a1.5 1.5 0 1 1 0 3M10 20v4H7V5h10v4.998a1 1 0 0 0 1 1h5V19H11a1 1 0 0 0-1 1m8-15 5 4.96h-5zm8 14h-2v-8.98a.995.995 0 0 0-.293-.729l-5-4.998A.996.996 0 0 0 18 4H7a1 1 0 0 0-1 1v19a1 1 0 0 0 1 1h3v2a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1m-7.5 3a1.5 1.5 0 0 1 0 3H18v-3Zm-4 0a.5.5 0 0 1 0 1H13v-1Zm6-6a.5.5 0 0 1 0 1h-11a.5.5 0 0 1 0-1Zm0-3a.5.5 0 0 1 0 1h-11a.5.5 0 0 1 0-1Zm-6-3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1Zm0-3a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1Z" className="pdf-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgPdf32);
export default Memo;