import { defineMessage, useIntl } from 'react-intl';
import { ArrowLargeRight32 } from '@packages/themes/icons';
import type { ButtonProps } from '@mui/material';
import {
  type GlycerinButtonClickPayload,
  type GlycerinFormSubmitClickPayload,
} from '@packages/tracking';
import { Button } from '../Button';

export type LoginButtonProps = ButtonProps & {
  clickTrackingProps?:
    | Omit<GlycerinButtonClickPayload, 'label'>
    | Omit<GlycerinFormSubmitClickPayload, 'label'>;
};

const loginLabel = defineMessage({
  id: 'slot.account.login',
  defaultMessage: 'Anmelden',
});

export const LoginButton: React.FC<LoginButtonProps> = ({
  clickTrackingProps,
  onClick,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const { type } = props;
  const { category, detail } = clickTrackingProps ?? {};

  return (
    <Button
      clickTrackingProps={{
        ...clickTrackingProps,
        category: category ?? (type === 'submit' ? 'form-submit-click' : 'button-click'),
        detail: detail ?? 'Login Button click',
        label: formatMessage(loginLabel),
      }}
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
      }}
      {...props}
      endIcon={<ArrowLargeRight32 />}
      color="primary"
    >
      {formatMessage(loginLabel)}
    </Button>
  );
};
