import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Notification } from '@packages/shared';
import { messages } from './PromotionBanner.messages';

export const ExpiredNotification = ({
  initialOpenNotification,
}: {
  initialOpenNotification: boolean;
}) => {
  const { formatMessage } = useIntl();
  const [openNotification, setOpenNotification] = useState(initialOpenNotification);
  return (
    openNotification && (
      <Notification
        open={openNotification}
        severity="warning"
        autoHideDuration={6000}
        onClose={() => setOpenNotification(false)}
      >
        {formatMessage(messages.expiredTitle)}
      </Notification>
    )
  );
};
