import type { HashDataObject } from '../../types/hashDataObject';

export const useHashParameters = (): HashDataObject => {
  if (typeof window !== 'undefined') {
    const hashparams = new URLSearchParams(window.location.hash.substring(1));

    return {
      category: hashparams.get('nav-c') || undefined,
      position: hashparams.get('nav-i') || undefined,
      searchNo: hashparams.get('search-no') === 'true',
      token: hashparams.get('token') || undefined,
    };
  }

  return {
    category: undefined,
    position: undefined,
    searchNo: undefined,
    token: undefined,
  };
};
