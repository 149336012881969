import type { SxProps } from '@mui/material';

const basicSxProps: SxProps = {
  alignItems: 'center',
  display: 'flex',
  flexFlow: 'wrap',
  fontWeight: 600,
};

export const availabilityStateStyles = new Map<String, SxProps>();
availabilityStateStyles.set('AVAILABLE', {
  color: 'success.main',
  ...basicSxProps,
});
availabilityStateStyles.set('NOT_AVAILABLE', {
  color: 'error.main',
  ...basicSxProps,
});
availabilityStateStyles.set('NOT_YET_AVAILABLE', {
  color: 'warning.main',
  ...basicSxProps,
});
availabilityStateStyles.set('UNSELECTED', {
  ...basicSxProps,
  color: 'text.primary',
  fontWeight: 300,
});
