'use client';

import { useState, useMemo, type ReactNode } from 'react';
import type { DialogPresetProps } from '@packages/shared';
import { DialogPreset, useI18n, Box } from '@packages/shared';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { useConfig } from '@packages/utilities';
import { MiniDetailViewDataDocument } from '@packages/gql/generated/shopping/MiniDetailViewDataDocument';
import { MiniDetailViewLayerFragmentFragmentDoc } from '@packages/gql/generated/shopping/MiniDetailViewLayerFragmentFragmentDoc';
import { useQuery } from 'urql';
import { useIntl } from 'react-intl';
import { getProductAndFallbackId } from '../helpers';
import { AddToBasketButton, type AddToBasketButtonProps } from '../AddToBasketButton';
import { EditInWishlistButton } from '../EditInWishlistButton';
import { MiniDVSkeleton } from './MiniDVSkeleton';
import { MiniDV } from './MiniDV';
import { miniDVMessages } from './messages';

/* GraphQL */ `
  fragment MiniDetailViewLayerFragment on Product {
    ...MiniDetailViewFragment
    ...AddToBasketFragment
    ...EditInWishlistFragment
  }
`;

/* GraphQL */ `
  query MiniDetailViewData(
    $productId: String!
    $fallbackId: String!
    $locale: String!
    $lang: String!
    $skipAplus: Boolean! = false
  ) {
    product(id: $productId, fallbackId: $fallbackId, locale: $locale) {
      ...MiniDetailViewLayerFragment
      sku
    }
  }
`;

export type MiniDVLayerProps = {
  /**
   * product sku
   */
  sku: string;
  /**
   * product akl
   */
  akl: string;
  /**
   * it needs to update current lineItem in the basket.
   * without lineItemId it will add new product in the the basket
   */
  lineItemId?: string;
  /**
   * quantity of the lineItem
   */
  quantity: number;
  /**
   * service layer from the inspire
   */
  serviceLayer?: ReactNode;
  /**
   * mode for handling the data
   * @default 'ATB' - Add to Basket
   * 'EIW' - Edit in Wishlist
   */
  mode?: 'ATB' | 'EIW';
} & Pick<DialogPresetProps, 'open' | 'onClose'> &
  Pick<AddToBasketButtonProps, 'onClick'>;

export const MiniDVLayer = ({
  sku,
  akl,
  lineItemId,
  quantity,
  serviceLayer,
  open,
  onClose,
  onClick: onButtonClick,
  mode = 'ATB',
}: MiniDVLayerProps) => {
  const [selectedVariation, setSelectedVariation] = useState<string | null>(sku);
  const { formatMessage } = useIntl();
  const { locale, language } = useI18n();
  const {
    product: {
      aplus: { enabled: aplusEnabled },
    },
  } = useConfig();

  const [{ data, fetching }] = useQuery({
    query: MiniDetailViewDataDocument,
    variables: {
      ...getProductAndFallbackId(akl, selectedVariation!),
      locale,
      lang: language,
      skipAplus: !aplusEnabled,
    },
    context: useMemo(() => ({ suspense: false }), []),
    pause: !open,
  });

  if (!data?.product) {
    return null;
  }
  const product = getFragmentData(MiniDetailViewLayerFragmentFragmentDoc, data.product);
  return (
    <DialogPreset
      open={open}
      onClose={onClose}
      title={formatMessage(miniDVMessages.title)}
      buttons={
        <Box>
          {mode === 'EIW' && <EditInWishlistButton data={product} prevSku={sku} />}
          {mode === 'ATB' && (
            <AddToBasketButton
              data={product}
              lineItemId={lineItemId}
              onClose={onClose}
              onClick={onButtonClick}
            />
          )}
        </Box>
      }
    >
      {fetching ? (
        <MiniDVSkeleton />
      ) : (
        <MiniDV
          data={product}
          selectedVariation={selectedVariation}
          setSelectedVariation={setSelectedVariation}
          quantity={quantity}
          serviceLayer={serviceLayer || null}
        />
      )}
    </DialogPreset>
  );
};
