import { defineMessages } from 'react-intl';

export const quantitySelectionMessages = defineMessages({
  label: {
    id: 'quantitySelection.label',
    defaultMessage: 'Anzahl',
  },
  increase: {
    id: 'quantitySelection.increase',
    defaultMessage: 'Anzahl erhöhen',
  },
  decrease: {
    id: 'quantitySelection.decrease',
    defaultMessage: 'Anzahl verringern',
  },
});
