import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import type { EfficiencyFlagProps } from '@packages/shared';
import { Card, CardContent, Divider, EfficiencyFlag, Stack } from '@packages/shared';
import type { FC } from 'react';
import { useIntl } from 'react-intl';
import { EfficiencyFlagListFragmentFragmentDoc } from '@packages/gql/generated/shopping/EfficiencyFlagListFragmentFragmentDoc';
import { DownloadItem } from '../DownloadItem';
import { getEfficiencyFlagType } from './getEfficiencyFlagType';
import { getEfficiencyFlagListMessage } from './getEfficiencyFlagListMessage';
import { useLayerTracking } from '../Tracking/hooks';

/* GraphQL */ `
  fragment EfficiencyFlagListFragment on Product {
    tireEfficiency {
      link
      flags {
        level
        type
      }
    }
    powerEfficiencyFlags {
      color
      level
      link
      text
      hasOldLabel
    }
    downloads {
      type
      ...DownloadItemFragment
    }
  }
`;

export type EfficiencyFlagListProps = {
  /** List with efficiencyFlag properties to simply render component. */
  data: FragmentType<typeof EfficiencyFlagListFragmentFragmentDoc>;
  /**
   * Boolean to decide if layout should be rendered as row or column.
   * @default false
   * */
  horizontal?: boolean;
  /**
   * Size of the EfficiencyFlags.
   * @default "large"
   */
  variant?: EfficiencyFlagProps['variant'];
};

/**
 * EfficiencyFlagList is a list of the `EfficiencyFlag` [component](https://empiriecom.gitlab.io/core/frontend/turborepo/?path=/docs/components-efficiencyflag--tire-efficiency).
 * It has a special layout variant used in the detail view.
 * */
export const EfficiencyFlagList: FC<EfficiencyFlagListProps> = ({
  data: efficiencyFlagsData,
  horizontal = false,
  variant = 'large',
}) => {
  const { powerEfficiencyFlags, tireEfficiency, downloads } = getFragmentData(
    EfficiencyFlagListFragmentFragmentDoc,
    efficiencyFlagsData,
  );
  const { formatMessage } = useIntl();
  const { trackViewLayer } = useLayerTracking();

  // Frontend logic - this should be removed once the gql backend supports query batching. -> https://empiriecom.atlassian.net/browse/INFORM-2588
  const productDataSheetsDownloads = downloads.filter(
    (download) => download.type === 'PRODUCT_DATASHEET',
  );

  const trackClick = (eventType: string) => () => {
    trackViewLayer(`EEK Label(${eventType}`);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={1} direction={horizontal ? 'row' : 'column'}>
          {powerEfficiencyFlags?.map((flag, idx) => {
            const type = getEfficiencyFlagType({
              type: 'POWEREFFICIENCY',
              hasOldLabel: flag.hasOldLabel,
            });

            return (
              type && (
                <EfficiencyFlag
                  text={flag.level}
                  type={type}
                  labelColor={flag.color as EfficiencyFlagProps['labelColor']}
                  // label next to the flag should only be shown if multiple flags are shown
                  label={powerEfficiencyFlags.length > 1 ? flag.text : undefined}
                  variant={variant}
                  overlay={{ title: flag.text, imgUrl: flag.link }}
                  onClickHandler={trackClick('powerEfficiencyFlag')}
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                />
              )
            );
          })}
          {tireEfficiency?.flags &&
            tireEfficiency.flags.map((flag) => {
              const type = getEfficiencyFlagType({ type: flag.type });
              const titleMsg = getEfficiencyFlagListMessage(flag.type);

              return (
                type && (
                  <EfficiencyFlag
                    text={flag.level}
                    type={type}
                    variant={variant}
                    overlay={{
                      title: titleMsg ? formatMessage(titleMsg) : '',
                      imgUrl: tireEfficiency.link,
                    }}
                    onClickHandler={trackClick('tireEfficiencyFlag')}
                    key={flag.level}
                  />
                )
              );
            })}
        </Stack>
      </CardContent>
      {productDataSheetsDownloads.length > 0 && (
        <>
          <Divider />
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 1,
              '&:last-child': { pb: 1 },
            }}
          >
            <Stack direction="column" spacing={1} alignItems="center">
              {productDataSheetsDownloads.map((download, idx) => (
                <DownloadItem
                  renderIcon={false}
                  data={download}
                  // index should only be shown, if there is more then one download
                  idx={productDataSheetsDownloads.length > 1 ? idx + 1 : undefined}
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                />
              ))}
            </Stack>
          </CardContent>
        </>
      )}
    </Card>
  );
};
