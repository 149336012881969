/* @jsxImportSource react */

import type { Locale } from '@packages/config';
import config from '@packages/config';
import type { Device } from '@packages/themes/src/default';
import { localizedPathnameCacheKey } from '@packages/config/src/default';

import { Header, type HeaderProps } from './Header';
import { getNavigationDataEndpoint } from './Navigation/getNavigationDataEndpoint';
import { SwrConfigClientWrapper } from './SwrConfigClientWrapper';
import { fetchRequiredStaticJson } from './api';

export type HeaderLoaderProps = {
  locale: Locale;
  device: Device;
} & HeaderProps;

export const HeaderLoader = async ({ locale, device, isMinimal }: HeaderLoaderProps) => {
  const {
    clientId,
    navigation: { loadSliced },
  } = config;

  const navEndpoint = getNavigationDataEndpoint(
    clientId,
    device === 'desktop' ? 'web' : 'mob',
    locale,
    loadSliced,
  );

  const data = await fetchRequiredStaticJson(navEndpoint, { componentName: 'HeaderLoader' });

  return (
    <SwrConfigClientWrapper fallback={{ [navEndpoint]: data, [localizedPathnameCacheKey]: null }}>
      <Header isMinimal={isMinimal} />
    </SwrConfigClientWrapper>
  );
};
