import { defineMessages } from 'react-intl';

export const editInWishlistMessage = defineMessages({
  soldOut: {
    id: 'EditInWishlistButton.SoldOut',
    defaultMessage: 'Ausverkauft',
    description: 'this will be displayed on the button',
  },
  buttonText: {
    id: 'EditInWishlistButton.ButtonText',
    defaultMessage: 'Änderung übernehmen',
    description: 'this will be displayed on the button',
  },
  successMessage: {
    id: 'EditInWishlistButton.SuccessMessage',
    defaultMessage: 'Dein Artikel wurde erfolgreich geändert.',
    description: 'this will be displayed on notification provider',
  },
  failedMessage: {
    id: 'EditInWishlistButton.FailedMessage',
    defaultMessage: 'Entschuldige, irgendetwas ist schief gelaufen. Probiere es später noch einmal',
    description: 'this will be displayed on notification provider',
  },
});
