import type { ButtonProps } from '@mui/material';
import { Button as MuiButton } from '@mui/material';
import {
  useTracking,
  type GlycerinButtonClickPayload,
  type GlycerinFormSubmitClickPayload,
  type GTMEventGlycerinButtonClick,
  type GTMEventGlycerinFormSubmitClick,
  useIsBot,
} from '@packages/tracking';
import { getCanonicalUrl } from '@packages/utilities';
import type { ReactNode } from 'react';

export interface IconButtonProps extends Omit<ButtonProps, 'startIcon' | 'endIcon' | 'children'> {
  /** Icon to render in button */
  icon: ReactNode;
  clickTrackingProps?: GlycerinButtonClickPayload | GlycerinFormSubmitClickPayload | null;
}

/** Component to render only icon in the standard button */
export const IconButton = ({ icon, onClick, clickTrackingProps, ...props }: IconButtonProps) => {
  const dispatchGTMEvent = useTracking();
  const { type, href } = props;
  const { category, target, detail, label, placement, variantIds, custom } =
    clickTrackingProps ?? {};
  let linkTarget = href;
  const isBot = useIsBot();
  if (isBot) {
    linkTarget = getCanonicalUrl(href ?? '');
  }

  return (
    <MuiButton
      onClick={(event) => {
        if (clickTrackingProps !== null) {
          if (type === 'submit') {
            dispatchGTMEvent<GTMEventGlycerinFormSubmitClick>({
              event: 'FormSubmitClick',
              FormSubmitClickData: {
                category: category ?? 'form-submit-click',
                target: target ?? href,
                detail,
                formId:
                  clickTrackingProps && 'formId' in clickTrackingProps
                    ? clickTrackingProps.formId
                    : undefined,
                label,
                placement,
                variantIds,
                custom,
              },
            });
          } else {
            dispatchGTMEvent<GTMEventGlycerinButtonClick>({
              event: 'ButtonClick',
              ButtonClickData: {
                category: category ?? 'icon-button-click',
                target: target ?? href,
                detail,
                label,
                placement,
                variantIds,
                custom,
              },
            });
          }
        }

        if (onClick) {
          onClick(event);
        }
      }}
      {...props}
      href={linkTarget}
      startIcon={icon}
    />
  );
};
