import { defineMessages } from 'react-intl';

export const availabilityInfoMessages = defineMessages({
  deliveryMessageFallback: {
    id: 'availabilityMessage.fallback',
    defaultMessage: 'Eine Lieferaussage ist zur Zeit leider nicht möglich',
  },
  deliveryMessageUnselected: {
    id: 'availabilityMessage.unselected',
    defaultMessage: 'Wähle bitte eine Variante um Lieferinformationen zu sehen',
  },
});
