import {
  disabledTileStyle,
  selectedTileStyle,
  selectedDisabledTileStyle,
  defaultTileStyle,
} from '../styles';

export function getStyle(isAvailable: boolean, isSelected: boolean) {
  if (!isAvailable && !isSelected) {
    return disabledTileStyle;
  }
  if (isSelected && isAvailable) {
    return selectedTileStyle;
  }
  if (!isAvailable && isSelected) {
    return selectedDisabledTileStyle;
  }

  return defaultTileStyle;
}
