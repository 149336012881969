import type { FC } from 'react';
import type { FormatNumberOptions } from 'react-intl';
import { useIntl } from 'react-intl';
import { useConfig } from '@packages/utilities';
import { useFormatPrice } from '../../hooks/useFormatPrice/useFormatPrice';
import { Box } from '../Box';
import { Flag } from '../Flag';
import { Typography, type TypographyProps } from '../Typography';
import { Stack } from '../Stack';

export type ProductPriceProps = {
  /**
   * See Intl.NumberFormat.currency:
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat.
   * Example: "EUR"
   */
  currency: FormatNumberOptions['currency'];
  /**
   * This should be int number representing the exact value of the current price in the smallest currency.
   * Example: 1499 (cent)
   */
  price: number;
  /**
   * This should be int number representing the exact value of the oldPrice in the smallest currency.
   * Example: 1499 (cent)
   */
  oldPrice?: number;
  /**
   * number representing the exact value of the saving.
   * Example: 2999 (cent) for currency and 29 for percentage values
   */
  saving?: number;
  /**
   * The discount type controls if the discount flag is rendered in percent or as a currency value
   * @default "percent"
   */
  discountType?: 'percent' | 'currency';
  /**
   * Unit identifier as string. toggles label `price.unit`
   * Example: 'Stk.' => 14,99€ / Stk.
   */
  unit?: string;
  /**
   * This should be int number representing the exact value of the unitPrice in the smallest currency.
   * Example: 1499 (cent)
   */
  unitPrice?: number;
  /**
   * Unit amount value. Used to extend the unit label.
   * Example: 100 => 2,49€ / 100 ml
   */
  amount?: number;
  /**
   * small style for usage in product cards etc.
   * @default false
   */
  small?: boolean;
  /**
   * style for price typography
   * @default "h4"
   */
  fontVariantPrice?: TypographyProps['variant'];
  /**
   * price text alignments
   * @default "left"
   */
  alignment?: 'left' | 'center' | 'right';
  /**
   * Has the article different prices for different sizes; toggles `price.from` label
   * @default false
   */
  isStartPrice?: boolean;
  /**
   * Flag indicates if legal hint shoul be shown
   * Example: inkl. MwSt. zzgl.  Versandkosten
   * @default false
   */
  showLegalHints?: boolean;
  /**
   * Indicates if discount flag is shown when oldPrice is set
   * @default false
   */
  hasDiscountFlag?: boolean;
  /**
   * UVP-Label for oldPrice. Optional, only rendered when provided
   */
  uvpLabel?: string;
  /**
   * Discount Info-Label for priceFlag. Optional, only appended to FlagText when provided
   */
  discountInfoLabel?: string;
  /**
   * If `true`, even if the unitPrice is not set, the necessary space will still be blocked
   */
  useUnitPriceSpacer?: boolean;
  /**
   * Discount should be shown as negative number. Optional, only used when provided
   * @example -20% instead of 20%
   * @default false
   */
  displayNegativeDiscountNumber?: boolean;
  /**
   * The minimum installment payment amount
   */
  minInstallment?: number;
  /**
   * The maximum number of installments
   */
  noOfInstallments?: number;
  /**
   * Flag to determine if installments payment should be shown
   */
  hasInstallmentsPayment?: boolean;
};

/**
 * ProductPrice component based on [Zeplin Layout](https://app.zeplin.io/project/5d493736066e193158b2d910/screen/5ef9a82b5ef44513fd192bcf)
 * */
export const ProductPrice: FC<ProductPriceProps> = ({
  currency,
  price,
  saving,
  oldPrice,
  discountType = 'percent',
  amount,
  unit,
  unitPrice,
  small = false,
  fontVariantPrice = 'h4',
  alignment = 'left',
  isStartPrice = false,
  showLegalHints = false,
  hasDiscountFlag = false,
  uvpLabel,
  discountInfoLabel,
  useUnitPriceSpacer,
  displayNegativeDiscountNumber = false,
  minInstallment,
  noOfInstallments,
  hasInstallmentsPayment,
}) => {
  const { formatNumber, formatMessage } = useIntl();
  const { formatPrice } = useFormatPrice();
  const {
    product: {
      price: { hasSalePrimaryColor },
    },
  } = useConfig();

  const saleColor = hasSalePrimaryColor ? 'sale.main' : 'text.primary';

  const formattedDiscount = (savingValue: number) => {
    switch (discountType) {
      case 'currency':
        return [discountInfoLabel, formatPrice(savingValue, currency)].join(' ');
      default:
        return [
          discountInfoLabel,
          formatNumber(savingValue / 100, {
            style: 'percent',
          }),
        ].join(' ');
    }
  };

  return (
    <Box sx={{ textAlign: alignment }}>
      {!!oldPrice && (
        <Stack
          direction="row"
          sx={{ rowGap: 0, columnGap: 1, justifyContent: alignment, flexWrap: 'wrap' }}
        >
          {/** TODO: after productPrice is moved into own module, the localization-text of uvpLabel and discountInfoLabel should be defined within the component */}
          <Stack direction="row" spacing={0.5}>
            {uvpLabel && uvpLabel.length > 0 && (
              <Typography component="div" variant="body3" color="text.dark">
                {uvpLabel}
              </Typography>
            )}
            <Typography
              component="div"
              variant="body3"
              color="text.dark"
              sx={{ textDecoration: 'line-through' }}
            >
              {formatPrice(oldPrice, currency)}
            </Typography>
          </Stack>
          {hasDiscountFlag && saving && (
            <Flag
              text={
                displayNegativeDiscountNumber
                  ? `- ${formattedDiscount(saving)}`
                  : formattedDiscount(saving)
              }
              type="sale"
            />
          )}
        </Stack>
      )}
      <Typography
        component="div"
        variant={small ? 'body1' : fontVariantPrice}
        fontWeight="fontWeightBold"
        color={oldPrice ? saleColor : 'text.dark'}
      >
        {isStartPrice
          ? `${formatMessage({ id: 'price.start.from', defaultMessage: 'ab' })} ${formatPrice(
              price,
              currency,
            )}`
          : formatPrice(price, currency)}
      </Typography>
      {minInstallment && hasInstallmentsPayment && (
        <Typography variant="body3" color="text.darkTransparent">
          {noOfInstallments
            ? formatMessage(
                {
                  id: 'price.installmentsPayment.withAmountAndNoOfInstallments',
                  defaultMessage: 'Oder ab {paymentAmount} in {noOfInstallments} Raten',
                },
                {
                  paymentAmount: formatPrice(minInstallment, currency),
                  noOfInstallments,
                },
              )
            : formatMessage(
                {
                  id: 'price.installmentsPayment.onlyMonthlyAmount',
                  defaultMessage: 'Oder ab {paymentAmount} monatlich',
                },
                {
                  paymentAmount: formatPrice(minInstallment, currency),
                },
              )}
        </Typography>
      )}
      {!!unit && !!unitPrice ? (
        <Typography component="div" variant="body3" color="text.darkTransparent" gutterBottom>
          {formatPrice(unitPrice, currency)} / {amount || ''}
          {unit}
        </Typography>
      ) : (
        useUnitPriceSpacer && (
          <Typography component="div" variant="body3" color="transparent" gutterBottom>
            &nbsp;
          </Typography>
        )
      )}
      {showLegalHints && (
        <Typography component="div" variant="body3" color="text.darkTransparent" gutterBottom>
          {formatMessage({
            id: 'price.showLegals',
            defaultMessage: 'inkl. MwSt. zzgl. Versandkosten',
          })}
        </Typography>
      )}
    </Box>
  );
};
