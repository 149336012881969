import { logger } from '@packages/utilities';
import DOMPurify from 'isomorphic-dompurify';
import type { NextWebVitalsMetric } from 'next/app';

declare global {
  interface Window {
    [key: string]: number;
  }
}

//* don't destructure process.env variables. NextJS can't resolve/replace the the destructured varibales in the bundles */
// eslint-disable-next-line prefer-destructuring
const VITALS_ACCOUNT_ID = process.env.VITALS_ACCOUNT_ID;
// eslint-disable-next-line prefer-destructuring
const VITALS_URL = process.env.VITALS_URL;

/** Helper function to report web-vitals to an analytics api
 * See here for more info: https://nextjs.org/docs/advanced-features/measuring-performance */
const sendWebVitals = (metric: NextWebVitalsMetric) => {
  if (metric.label === 'web-vital') {
    logger.info(metric);

    if (VITALS_ACCOUNT_ID && VITALS_URL) {
      const body = JSON.stringify({
        accountId: VITALS_ACCOUNT_ID,
        name: metric.name,
        url: window.location.href,
        value: metric.value,
      });

      return (
        (navigator.sendBeacon && navigator.sendBeacon(VITALS_URL, body)) ||
        fetch(VITALS_URL, {
          body,
          method: 'POST',
          keepalive: true,
          headers: {
            'Content-Type': 'application/json',
          },
        })
      );
    }
  }
  if (metric.label === 'custom') {
    logger.info(metric);

    // add custom metrics as global variables to window
    // for rum monitoring
    if (typeof document !== 'undefined') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      // replace dashes and dots in metric name to prevent syntax errors
      // and sanitize the value to prevent XSS attacks
      script.innerHTML = DOMPurify.sanitize(
        `var ${metric.name.replaceAll('-', '').replaceAll('.', '')}=${metric.value}`,
      );
      document.head.appendChild(script);
    }

    if (VITALS_ACCOUNT_ID && VITALS_URL) {
      const body = JSON.stringify({
        accountId: VITALS_ACCOUNT_ID,
        name: metric.name,
        url: window.location.href,
        value: metric.value,
      });

      return (
        (navigator.sendBeacon && navigator.sendBeacon(VITALS_URL, body)) ||
        fetch(VITALS_URL, {
          body,
          method: 'POST',
          keepalive: true,
          headers: {
            'Content-Type': 'application/json',
          },
        })
      );
    }
  }
  return undefined;
};

export { sendWebVitals };
