import { useParams } from 'next/navigation';

export const useSearchQuery = () => {
  const routeParams = useParams();

  // NOTE: null check is for pages/app router compat mode, later for pure app router this can never be null
  if (!routeParams) return null;

  if (Array.isArray(routeParams.searchquery)) {
    throw new Error('multiple route segments not supported for searchquery');
  }

  return routeParams.searchquery
    ? decodeURIComponent(routeParams.searchquery).replace(/\+/g, ' ')
    : null;
};
