import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { GetGlycerinServicesCurrencyFragmentFragmentDoc } from '@packages/gql/generated/shopping/GetGlycerinServicesCurrencyFragmentFragmentDoc';
import { GetGlycerinServicesFragmentFragmentDoc } from '@packages/gql/generated/shopping/GetGlycerinServicesFragmentFragmentDoc';
import type { GlycerinService } from '@packages/tracking';

/* GraphQL */ `
  fragment GetGlycerinServicesFragment on APlus {
    clusters {
      label
      services {
        gratis
        name
        price
        preselected
      }
    }
  }
`;

/* GraphQL */ `
  fragment GetGlycerinServicesCurrencyFragment on ProductPrice {
    currency
  }
`;

export type GetGlycerinServicesProps = {
  aplus: FragmentType<typeof GetGlycerinServicesFragmentFragmentDoc> | null | undefined;
  price: FragmentType<typeof GetGlycerinServicesCurrencyFragmentFragmentDoc>;
};

export const getGlycerinServices = ({ aplus, price }: GetGlycerinServicesProps) => {
  if (!aplus) return { services: undefined };

  const { clusters } = getFragmentData(GetGlycerinServicesFragmentFragmentDoc, aplus);
  const { currency } = getFragmentData(GetGlycerinServicesCurrencyFragmentFragmentDoc, price);

  if (clusters.length === 0) return { services: undefined };

  const services: GlycerinService[] = [];

  clusters.forEach((cluster) =>
    cluster?.services.forEach((service) => {
      services.push({
        currency,
        displayedPrice: service.gratis || !service.price ? 0 : Math.round(service.price * 100),
        serviceType: cluster.label,
        name: service.name || '',
        quantity: service.preselected ? 1 : 0,
      });
    }),
  );

  return {
    services,
  };
};
