import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgUser32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M16 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12m0 1a7 7 0 1 1 0-14 7 7 0 0 1 0 14" /><path d="M10.04 19C4 22.383 3.999 29 3.999 29h24c0-6.443-5.959-10-5.959-10-6.524 4.591-12 0-12 0m.995-.51c.317.214.686.429 1.103.631 2.855 1.383 6.029 1.383 9.327-.938l.531-.374.557.333c.22.131.579.373 1.032.726.746.579 1.49 1.265 2.186 2.057 2.011 2.287 3.228 4.989 3.228 8.076v1h-26v-1c0-.437.063-1.156.249-2.066a13.691 13.691 0 0 1 1.729-4.427 12.38 12.38 0 0 1 4.574-4.379l.602-.337.529.443c.05.042.17.133.352.256z" /></SvgIcon>;
};
const Memo = memo(SvgUser32);
export default Memo;