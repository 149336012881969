import type { SxProps } from '@mui/material';

export const defaultStyles: SxProps = {
  boxShadow: 1,
  borderWidth: '1px',
  borderColor: 'transparent',
  ':hover': {
    borderColor: 'text.dark',
    boxShadow: 4,
  },
};

export const activeStyles: SxProps = {
  boxShadow: 4,
};
