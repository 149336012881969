import type { EcState as EcStateCookie } from '@empiriecom/mybuy-session/ApiKey';

type EcStateLoggedOut = {
  /** Logged in status */
  loggedIn: false;
};

type EcStateLoggedIn = {
  /** Logged in status */
  loggedIn: true;
  /** Bonusprogram status */
  bonusProgram: boolean;
  /** Customer's Credit affinity */
  creditAffinity: boolean;
  /** Customer without password (legacy login method) */
  UserOhnePasswort: boolean;
};

export type EcState = EcStateLoggedOut | EcStateLoggedIn;

/**
 * Parses the cookies and returns the EcState data of the customer.
 *
 * @param {Partial<{ [key: string]: string }>} cookies - The cookies object containing customer information.
 * @returns {EcState} The EcState data of the customer.
 */
export const getEcState: (cookies: Partial<{ [key: string]: string }>) => EcState = ({
  ecState,
}) => {
  if (!ecState) {
    return { loggedIn: false };
  }

  try {
    /*
      In the cookie is an encoded json object
      like "%7B%22li%22%3A%221%22%2C%22bop%22%3A%220%22%2C%22ca%22%3A%221%22%7D".
      So decode the string and parse to object.
    */
    const dataInCookie: EcStateCookie = JSON.parse(decodeURIComponent(ecState));

    if (dataInCookie.li !== '1') {
      return { loggedIn: false };
    }

    const { ca, bop, np } = dataInCookie;

    return {
      loggedIn: true,
      bonusProgram: bop === '1',
      creditAffinity: ca === '1',
      // currently np is given as number while it is declared as string so just use eqeq
      // eslint-disable-next-line eqeqeq
      UserOhnePasswort: np == '1',
    };
  } catch (err) {
    return { loggedIn: false };
  }
};
