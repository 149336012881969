import type { IconButtonProps as MuiIconButtonProps } from '@mui/material';
import { IconButton as MuiIconButton } from '@mui/material';
import { Cancel32 } from '@packages/themes/icons';
import {
  useTracking,
  type GlycerinButtonClickPayload,
  type GTMEventGlycerinButtonClick,
} from '@packages/tracking';

export type CloseIconButtonProps = Omit<MuiIconButtonProps, 'icon'> & {
  clickTrackingProps?: GlycerinButtonClickPayload;
};

/** Transparent icon button with cancel icon. Based on MUI IconButton https://mui.com/material-ui/api/icon-button/ */
export const CloseIconButton: React.FC<CloseIconButtonProps> = ({
  clickTrackingProps,
  onClick,
  ...props
}) => {
  const dispatchGTMEvent = useTracking();
  const { category, target, detail, label, placement, variantIds, custom } =
    clickTrackingProps ?? {};

  return (
    <MuiIconButton
      onClick={(event) => {
        dispatchGTMEvent<GTMEventGlycerinButtonClick>({
          event: 'ButtonClick',
          ButtonClickData: {
            category: category ?? 'button-click',
            target,
            detail: detail ?? 'Close icon Button',
            label,
            placement,
            variantIds,
            custom,
          },
        });
        if (onClick) {
          onClick(event);
        }
      }}
      data-testid="close-iconbutton"
      size="small"
      aria-label="Close"
      {...props}
    >
      <Cancel32 />
    </MuiIconButton>
  );
};
